import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";
import { auditsApi } from "../../../api/audit-api";
import { useAuth } from "../../../hooks/use-auth";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowRight";
import AuditIntroChapter from "./audit-intro-chapter";
import AuditIntroForm from "./audit-intro-form";

function AuditIntroduction(props) {
  const { auditId } = props;
  const { user } = useAuth();
  const [chapters, setChapters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewChapter, setIsNewChapter] = useState(false);

  const getIntroChapters = async () => {
    try {
      const data = await auditsApi.getIntroductionPartsByAudit(
        user.admin_id,
        auditId
      );
      setChapters(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChapter = async (chapterId) => {
    try {
      await auditsApi.deleteIntroductionPart(user.admin_id, chapterId);
      const updatedChapters = chapters.filter(
        (chapter) => chapter.id != chapterId
      );
      setChapters(updatedChapters);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getIntroChapters();
  }, []);

  useEffect(() => {
    if (isNewChapter) {
      getIntroChapters();
      setIsNewChapter(false);
    }
  }, [isNewChapter]);

  return (
    <div>
      <AuditIntroForm auditId={auditId} setIsNewChapter={setIsNewChapter} />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : chapters.length > 0 ? (
        chapters.map((chapter) => (
          <AuditIntroChapter
            id={chapter.id}
            number={chapter.chapter_number}
            title={chapter.chapter_title}
            auditId={auditId}
            deleteChapter={deleteChapter}
            setIsNewChapter={setIsNewChapter}
          />
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Typography sx={{ fontSize: 18 }}>
            Pas de chapitre enregistré pour cet audit
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default AuditIntroduction;
