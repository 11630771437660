import { Avatar, Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/fr'; 
import { useAuth } from '../../../hooks/use-auth';
import { Send } from '@mui/icons-material';
import { conversationApi } from '../../../api/conversation-api';
import { useMounted } from '../../../hooks/use-mounted';
import ConversationsList from './conversations-list';
import { useLocation } from 'react-router-dom';

moment.locale('fr')

const useStyles = makeStyles((theme) => ({
    scrollbar: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  }));


function DiscussionSection(props) {
  const isMounted = useMounted();
  const { newConversationByAdmin, setNewConversationByAdmin, auditId } = props
  const [conversations, setConversations] = useState([])
  const [filteredConversations, setFilteredConversations] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedId, setSelectedId] = useState(null)
  const [selectedLearnerId, setSelectedLearnerId] = useState(null)
  const [selectedAuditId, setSelectedAuditId] = useState(null)
  const [selectedAvatar, setSelectedAvatar] = useState("")
  const [selectedName, setSelectedName] = useState("")
  const [newMessage, setNewMessage] = useState("")
  const [socket, setSocket] = useState(null);
  const [searchValue, setSearchValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  const chatContainerRef = useRef(null)
  const classes = useStyles()
  const location = useLocation();
  const urlConversationId = new URLSearchParams(location.search).get('to_conversation');

  const getConversations = useCallback(async () => {
    try {
        const conversationsData = await conversationApi.getConversations(user.admin_id, searchValue)
        if (isMounted() && conversationsData.length > 0) {
            setConversations(conversationsData)
            setIsLoading(false)
            if (urlConversationId !== null && conversationsData.some(conv => conv.id === urlConversationId)) { 
                const conversationIndex = conversations.findIndex(conv => conv.id === urlConversationId)
                // readMessages(conversationsData[conversationIndex].id)
                setSelectedName(conversationsData[conversationIndex].learnerDetails.fullname)
                setSelectedAvatar(conversationsData[conversationIndex].learnerDetails.avatar)
                setSelectedId(conversationsData[conversationIndex].id)
                setSelectedIndex(conversationIndex)
            } else {
                setSelectedName(conversationsData[0].learnerDetails.fullname)
                setSelectedAvatar(conversationsData[0].learnerDetails.avatar)
                setSelectedId(conversationsData[0].id)
            }
            getConversationMessages(conversationsData[0].id, conversationsData[0].learnerDetails.fullname, conversationsData[0].learnerDetails.avatar)
        }
        else {
            setIsLoading(false)
        }
    } catch(err) {
        console.error(err);
    }
  }, [isMounted])

  const getConversationMessages = async (conversationId, learnerFullname, learnerAvatar) => {
    setSelectedAvatar(learnerAvatar ?? null)
    setSelectedName(learnerFullname)
    try {
        const messageData = await conversationApi.getChatMessages(user.admin_id, conversationId)
        if (isMounted()) {
            if (conversationId !== null) setMessages(messageData.messages)
            else {
                setMessages([])
            }
            
        }
    } catch(err) {
        console.error(err);
    }
  }

  const readMessages = async (conversationId) => {
    try {
        await conversationApi.readMessages(user.admin_id, conversationId)
    } catch(err) {
        console.error(err)
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
  
    // Fonction pour ajouter un zéro devant les nombres inférieurs à 10
    const addZero = num => (num < 10 ? '0' + num : num);
  
    // Comparaison de la date
    if (date.toDateString() === now.toDateString()) {
      // Aujourd'hui
      return addZero(date.getHours()) + ':' + addZero(date.getMinutes());
    } else if (date.toDateString() === new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toDateString()) {
      // Hier
      return 'Hier, à ' + addZero(date.getHours()) + ':' + addZero(date.getMinutes());
    } else if (date > new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())) {
      // Moins d'un an
      return addZero(date.getDate()) + '/' + addZero(date.getMonth() + 1) + ', à ' + addZero(date.getHours()) + ':' + addZero(date.getMinutes());
    } else {
      // Plus d'un an
      return addZero(date.getDate()) + '/' + addZero(date.getMonth() + 1) + '/' + date.getFullYear() + ', à ' + addZero(date.getHours()) + ':' + addZero(date.getMinutes());
    }
  }

  useEffect(() => {
    // Fait défiler automatiquement vers le bas après le rendu initial
    if (conversations.length > 0) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [selectedName, messages]);

  useEffect(() => {
    const url =  process.env.REACT_APP_WS_URL + `subdomain/${globalThis.localStorage.getItem('tenant')}/chat-server/`;
    // const ws = new WebSocket(`ws://127.0.0.1:7000/ws/chat-server/`);
    const ws = new WebSocket(url);
    setSocket(ws)

    ws.onopen = () => {
      console.log('Connected to WebSocket');
    };

   
    ws.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
      
        const newMessageObject = {
          id: eventData.id,
          type_author: eventData.type_author,
          conversation_id: eventData.conversation_id,
          content: eventData.content,
          sent_at: eventData.sent_at,
        };
      
        if (eventData.type === "chat.message" && conversations.some(conv => conv.id === eventData.conversation_id)) {
          const conversationIndex = conversations.findIndex(conv => conv.id === eventData.conversation_id);
      
          const updatedConversations = [...conversations];
          const conversationToUpdate = updatedConversations[conversationIndex];
      
          conversationToUpdate.lastMessage = newMessageObject;
          if (selectedId !== eventData.conversation_id) {
            conversationToUpdate.numberUnreadMessages += 1;
          }
      
          setConversations(updatedConversations);
      
          if (selectedId === eventData.conversation_id) {
            setMessages(prevMessages => [...prevMessages, newMessageObject]);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // Scroll to bottom
          }
        }
      };
      
    ws.onclose = () => {
      console.log('Disconnected from WebSocket');
    };

    return () => {
      ws.close();
    };
  }, [messages, conversations]);

//   const sendMessage = async () => {

//     if (selectedId === null) {
//         const firstMessageObject = {
//             type: "first.message.from.admin",
//             admin_id: user.admin_id,
//             learner_id: selectedLearnerId,
//             audit_id: selectedAuditId,
//             content: newMessage
//         } 
        
//         socket.send(JSON.stringify(firstMessageObject))

//     } else {
//         const newMessageObject = {
//             type: "chat.message",
//             conversation_id: selectedId,
//             type_author: "formateur",
//             content: newMessage,
//         }

//         socket.send(JSON.stringify(newMessageObject))
//     }

//     setNewMessage("")
//   }
const sendMessage = async () => {
    if (!newMessage.trim()) return;
  
    const newMessageObject = {
      type: selectedId ? "chat.message" : "first.message.from.admin",
      conversation_id: selectedId,
      type_author: "formateur",
      content: newMessage,
      admin_id: user.admin_id,
      learner_id: selectedLearnerId,
      audit_id: selectedAuditId,
      sent_at: new Date().toISOString(), // Add timestamp locally
    };
  
    socket.send(JSON.stringify(newMessageObject));
  
    setMessages(prevMessages => [...prevMessages, newMessageObject]);
    setNewMessage("");
    
    // Optional: Auto-scroll to the bottom
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };
  
  useEffect(() => {
    if (newConversationByAdmin !== null) {
        setSelectedName(newConversationByAdmin.fullname)
        setSelectedAvatar(newConversationByAdmin.avatar)
        setSelectedId(newConversationByAdmin.conversation_id)
        setSelectedLearnerId(newConversationByAdmin.learner_id)
        setSelectedAuditId(auditId)

        if (conversations.some(conv => conv.learnerDetails.id === newConversationByAdmin.learner_id)) {
            const conversationIndex = conversations.findIndex(conv => conv.learnerDetails.id === newConversationByAdmin.learner_id)
            setSelectedIndex(conversationIndex)
            getConversationMessages(newConversationByAdmin.conversation_id, newConversationByAdmin.fullname, newConversationByAdmin.avatar)
           
        } else {
            const updateConversations = [...conversations]
            const newLearnerDetails = {
                id: newConversationByAdmin.learner_id,
                fullname: newConversationByAdmin.fullname,
                avatar: ''
            }
            const conversationObject = {
                id: newConversationByAdmin.conversation_id,
                learnerDetails: newLearnerDetails,
                lastMessage: null,
                numberUnreadMessages: 0,
            }

            updateConversations.unshift(conversationObject)

            setConversations(updateConversations)
            setMessages([])
        } 
        
        setNewConversationByAdmin(null)
    }
  }, [newConversationByAdmin])


  useEffect(() => {
    setIsLoading(true)
    getConversations()
  }, [])


  useEffect(() => {
    if (searchValue) {
        const conversationsToFilter = conversations.filter(conv => conv.learnerDetails.fullname.toLowerCase().includes(searchValue.toLowerCase()))
        setFilteredConversations(conversationsToFilter)
    }
    else if (conversations.length > 0 && selectedId !== conversations[selectedIndex].id) {
        const rightIndex = conversations.findIndex(conv => conv.id === selectedId)
        setSelectedIndex(rightIndex)
    }
  }, [searchValue])


  return (
    <Grid 
        container 
        sx={{ px: 3, pb: 1 }}
    >
        <ConversationsList 
            conversations={searchValue.trim() === "" ? conversations : filteredConversations} 
            setConversations={searchValue.trim() === "" ? setConversations : setFilteredConversations}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setSelectedId={setSelectedId} 
            setSelectedLearnerId={setSelectedLearnerId}
            searchValue={searchValue} 
            setSearchValue={setSearchValue}
            readMessages={readMessages}
            formatDate={formatDate}
            getConversationMessages={getConversationMessages}
        />
        <Grid 
            item 
            xs={9}
        >
            {conversations?.length > 0 ? (
            <Grid 
                container
                xs={12}
                sx={{ 
                    p: 2 
                }}
            >
                <Grid 
                    xs={12} 
                    sx={{ mb: 4, display: 'flex', justifyContent: 'center', height: '10px' }}
                >
                    <Typography variant="h6">Conversation avec {selectedName} </Typography>
                </Grid>
                    <Grid 
                        item 
                        xs={12}
                        sx={{
                            height: '480px',
                            pr: 1,
                            mb: 4
                        }}
                        ref={chatContainerRef}
                        className={classes.scrollbar}
                    >
                        {messages?.map((message) => (
                            <Grid 
                            xs={12} 
                            sx={{ 
                                display: 'flex', 
                                mb: 3,
                                flexDirection: message.type_author === "formateur" ? 'row-reverse' : 'row',
                                // gap: 1 
                            }}>
                                <Grid 
                                    xs={6} 
                                    sx={{ 
                                        display: 'flex', 
                                        flexDirection: message.type_author === "formateur" ? 'row-reverse' : 'row',
                                        gap: 1
                                    }}
                                >
                                    {message.type_author !== "formateur" &&
                                    <Avatar 
                                        alt={selectedName} 
                                        src={selectedAvatar}
                                        sx={{ width: 60, height: 60 }}
                                    />
                                    }
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: message.type_author === "formateur" ? "end" : "start"
                                    }}>
                                        <Box
                                            xs={4} 
                                            sx={{ 
                                                color: message.type_author === "formateur" ? 
                                                    "#F5F6FC" : "#1c2531",
                                                backgroundColor: message.type_author === "formateur" ?
                                                    "#1c2531" : "#e8e8e8",
                                                borderRadius: '15px', 
                                                boxSizing: 'border-box',
                                                width: 'fit-content',
                                                height: 'fit-content',
                                            }}>
                                            <Typography sx={{ px: 1, pt: 0.8, pb: 1 }}>{message.content}</Typography>
                                        </Box>
                                        <Typography sx={{ fontSize: 11, textAlign: 'end', px: 1, mt: 0.5}}>{formatDate(message.sent_at)}</Typography>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                 <Grid 
                    container
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                 >
                    <Grid 
                        item 
                        xs={11}
                    >
                        <TextField 
                            value={newMessage}
                            onChange={(e) => {
                                setNewMessage(e.target.value)
                            }}
                            onFocus={() => {
                                if (conversations[selectedIndex].numberUnreadMessages > 0) {
                                    const updateConvs = [...conversations]
                                    updateConvs[selectedIndex].numberUnreadMessages = 0
                                    setConversations(updateConvs)
                                    readMessages(selectedId)
                                } 
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessage(selectedIndex)
                                }
                              }}
                            fullWidth
                        />
                    </Grid>
                    <Grid 
                        item 
                        xs={0.5}
                    >
                        <IconButton 
                            size="large" 
                            sx={{ mt: 1 }} 
                            disabled={newMessage.trim() === ''}
                            onClick={() => sendMessage()}
                        >
                            <Send />
                        </IconButton>
                    </Grid>
                 </Grid>
                 
            </Grid>) : (
            <Grid 
            container
            xs={12}
            sx={{ 
                p: 2,
                height: '100%',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {/* <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ mb: 1, fontSize: 18, fontWeight: 'bold' }}>Lancer une nouvelle conversation avec un apprenant</Typography>
                    <NewConversation />
                </Box> */}
            </Grid>
        )}
            
        </Grid>
    </Grid>
  )
}

export default DiscussionSection