import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, IconButton, InputAdornment, TextField, Typography, InputLabel } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

// Objet de style servant à customiser les champs d'identifiants
const useTextFieldStyles = makeStyles({
    root: {
        '& label': {
            color: '#251E74', // Couleur lorsque le label est en focus
            paddingLeft: '1.2rem',
            fontFamily: '"Montserrat", sans-serif'
        },
        '& label.Mui-focused': {
            color: '#251E74', // Couleur lorsque le label est en focus
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#251E74', // Couleur de la bordure
            },
            '&:hover fieldset': {
                borderColor: '#251E74', // Couleur de la bordure en survol
            },
            '&.Mui-focused fieldset': {
                borderColor: '#251E74', // Couleur de la bordure lorsque le TextField est en focus
            },
        },
        '& input:-webkit-autofill': {
            'transition': 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
            'background-color': 'initial !important', // Conservez la couleur de fond initiale
            'color': 'initial !important' 
        },
    }
  });

// Formulaire de connexion avec la bibliothèque Formik (le scénario en cas de mot de passe oublié est encore à gérer)
export const JWTForgotPassword = (props) => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth();
  const classes = useTextFieldStyles();
  const { handleForgottenPassword, setIsPasswordForgotten, setLoginError } = props

  const formik = useFormik({
    initialValues: {
      email: "",
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('L\'adresse mail doit être valide')
        .max(255)
        .required('Adresse mail requise'),
    }),
    onSubmit: async (values, helpers) => {
    //   try {
    //     const res = await login(values.email, values.password);

    //     if (isMounted() && res == true) {
    //       navigate('/dashboard', { replace: true});
    //     } else {
    //       toast.error('Email ou mot de passe incorrect(s)');
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     if (err instanceof TypeError && err.message === 'Failed to fetch') setLoginError("Connexion impossible : problème au niveau du serveur")
    //     if (isMounted()) {
    //       helpers.setStatus({ success: false });
    //       helpers.setErrors({ submit: err.message });
    //       helpers.setSubmitting(false);
    //     }
    //   }
    }
  });

    return (
        <>
            <Box sx={{display: 'flex' }}>
                <IconButton
                    size="large"
                    onClick={() => setIsPasswordForgotten(false)}
                >
                    <ArrowCircleLeftOutlinedIcon sx={{ color: '#251E74', width: '1.5rem', height: '1.5rem', mt: 0, p:0 }}
                         />
                </IconButton>
                <Typography
                    variant="h5 small"
                    sx={{
                        color: '#251E74',
                        mt: 1,
                        // px: 2
                    }}
                >
                    Mot de passe oublié ?
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ color: '#251E74', px: 0, mt: 2 }}>Entrez  et envoyez votre adresse e-mail pour recevoir un nouveau mot de passe de connexion.</Typography>
            </Box>
            <form
                noValidate
                onSubmit={formik.handleSubmit}
                {...props}>
                    <InputLabel sx={{ mt: 5, px: 0, mb: '5px',color:'black', fontWeight: 'medium', fontSize:12,  fontFamily: '"Lexend", sans-serif', }}>Adresse e-mail</InputLabel>
                <TextField
                    variant="outlined"
                    // autofocus
                    // error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    // helperText={formik.touched.email && formik.errors.email}
                    // label="Email"
                    // margin="normal"
                    name="email"
                    // onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                    className={classes.root}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailOutlinedIcon sx={{ color: '#251E74' }} />
                            </InputAdornment>
                          ),
                        style: {
                            color: '#251E74', // Couleur de l'input
                        },
                    }}
                    sx={{ }}
                />
                {formik.errors.submit && (
                    <Box>
                    <FormHelperText error>
                        {formik.errors.submit}
                    </FormHelperText>
                    </Box>
                )}
                <Box sx={{ mt: 2, p: 0 }}>
                    <Button
                 disabled={formik.isSubmitting}
                 fullWidth
                 size="large"
                 type="submit"
                 // variant="contained"
                 sx={{mt:3,
                   fontFamily: '"Lexend", sans-serif',
                   color: 'white',
                   padding:'5px',
                   background: '#251E74',
                   border:'1px solid #251E74',
                   '&:hover': {
                     color: '#251E74',
                     backgroundColor: "white"
                       // '#64b15b'
                   },
                   fontSize: 18,
                 }}
                    >
                        ENVOYER
                    </Button>
                </Box>
            </form>
        </>
  );
};
