import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { auditsApi } from "../../../api/audit-api";
import { useAuth } from "../../../hooks/use-auth";

function AuditIntroForm(props) {
  const {
    auditId,
    setIsNewChapter,
    setIsChapterUpdated,
    editionMode,
    existingChapter,
    setShowDetails,
  } = props;
  const [showForm, setShowForm] = useState(false);
  const [chapterNumber, setChapterNumber] = useState(
    editionMode ? existingChapter.chapter_number : 0
  );
  const [chapterTitle, setChapterTitle] = useState(
    editionMode ? existingChapter.chapter_title : ""
  );
  const [content, setContent] = useState(
    editionMode ? existingChapter.content : ""
  );
  const { user } = useAuth();

  const editorRef = useRef(null);

  const createIntroductionPart = async () => {
    try {
      await auditsApi.createIntroductionPart(
        user.admin_id,
        auditId,
        chapterNumber,
        chapterTitle,
        content
      );

      setIsNewChapter(true);
    } catch (err) {
      console.log(err);
    } finally {
      // A revoir en cas de formulaire non envoyé
      setShowForm(false);
      setChapterTitle("");
      setChapterNumber(0);
    }
  };

  const editIntroductionPart = async () => {
    try {
      const chapter = {
        id: existingChapter.id,
        number: chapterNumber,
        title: chapterTitle,
        content: content,
      };

      await auditsApi.editIntroductionPart(user.admin_id, chapter);
    } catch (err) {
      console.log(err);
    } finally {
      if (existingChapter.title != chapterTitle) {
        setIsNewChapter(true);
        setShowDetails(false);
      } else if (existingChapter.number != chapterNumber) {
        setIsNewChapter(true);
        setShowForm(false);
        setIsChapterUpdated(true);
      } else {
        setShowForm(false);
        setIsChapterUpdated(true);
      }
    }
  };

  return (
    <div>
      {showForm ? (
        <Grid
          item
          sx={{
            mt: 1,
            mr: 4,
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          xs={2.5}
          md={1.8}
        >
          <Button
            variant="contained"
            onClick={
              !editionMode ? createIntroductionPart : editIntroductionPart
            }
          >
            Enregistrer
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setShowForm(false);
              if (!editionMode) {
                setChapterTitle("");
                setChapterNumber(0);
              }
            }}
          >
            Annuler
          </Button>
        </Grid>
      ) : (
        <Grid
          item
          xs={editionMode ? 11 : 12}
          sx={{ mt: editionMode ? 2 : 1, mr: 4, mb: 2 }}
          display="flex"
          justifyContent={editionMode ? "flex-end" : "flex-start"}
        >
          {!editionMode ? (
            <Button
              variant="contained"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Ajouter un chapitre
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Modifier le chapitre
            </Button>
          )}
        </Grid>
      )}

      {showForm && (
        <Box
          sx={{
            backgroundColor: "#6060b8",
            borderRadius: "12px",
            p: 3,
          }}
        >
          <Grid container sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 22,
                  fontWeight: 650,
                  color: "white",
                }}
              >
                Numéro de chapitre :{" "}
              </Typography>
            </Grid>
            <Grid item sx={{}} xs={0.5}>
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={chapterNumber}
                onChange={(e) => setChapterNumber(e.target.value)}
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              />
            </Grid>
            <Grid xs={0.5} />
            <Grid item xs={1}>
              <Typography
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 22,
                  fontWeight: 650,
                  color: "white",
                }}
              >
                Titre :{" "}
              </Typography>
            </Grid>
            <Grid item sx={{}} xs={8}>
              <TextField
                value={chapterTitle}
                onChange={(e) => setChapterTitle(e.target.value)}
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              />
            </Grid>
          </Grid>
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_KEY}
            onInit={(_evt, editor) => (editorRef.current = editor)}
            initialValue={content}
            value={content}
            onEditorChange={(newContent, editor) => setContent(newContent)}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </Box>
      )}
    </div>
  );
}

export default AuditIntroForm;
