class ErrorsApi {
    async getErrors(admin_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/errors_users/get/`
      const values = {
        "admin_id": admin_id
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json)
    }
    async getLinksErrors(admin_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/errors_links/get/`
      const values = {
        "admin_id": admin_id
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json)
    }
  }
  
  export const errorsApi = new ErrorsApi();