import { ButtonBase, Grid, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import AuditChapterDetails from "./audit-chapter-details";

const ROMAN_SYMBOLS = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
];

function AuditIntroChapter(props) {
  const { id, number, title, auditId, deleteChapter, setIsNewChapter } = props;
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Grid container sx={{ mt: 5, width: "100%" }}>
        <Grid item xs={11}>
          <ButtonBase
            sx={{ display: "flex", gap: 3 }}
            onClick={() => setShowDetails(!showDetails)}
          >
            <Typography variant="h4">
              Chapitre{" "}
              {number <= ROMAN_SYMBOLS.length ? ROMAN_SYMBOLS[number - 1] : ""}{" "}
              : {title}
            </Typography>
            <IconButton
              sx={{ borderRadius: "50%" }}
              // onClick={() => setShowDetails(!showDetails)}
            >
              {!showDetails ? (
                <KeyboardArrowRightIcon fontSize="large" />
              ) : (
                <KeyboardArrowDownIcon fontSize="large" />
              )}
            </IconButton>
          </ButtonBase>
        </Grid>

        <Grid item xs={1} sx={{ display: "flex", gap: 2 }}>
          <IconButton
            sx={{ borderRadius: "50%" }}
            onClick={() => deleteChapter(id)}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      {showDetails && (
        <AuditChapterDetails
          number={number}
          auditId={auditId}
          setIsNewChapter={setIsNewChapter}
          setShowDetails={setShowDetails}
        />
      )}
    </>
  );
}

export default AuditIntroChapter;
