import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, ListItem } from "@mui/material";
import { widgetsApi } from "../../../api/widget-api";

const WidgetSelector = ({ admin_id, selectedWidgets, toggleWidget }) => {
  const [allWidgets, setAllWidgets] = useState([]);

  useEffect(() => {
    const fetchWidgets = async () => {
      const widgets = await widgetsApi.getWidgets();
      setAllWidgets(widgets);
    };
    fetchWidgets();
  }, []);

  const isSelected = (widgetName) => {
    return selectedWidgets.some(
      (widget) =>
        widget.widget.component_name === widgetName && widget.is_selected
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)", // Toujours 3 colonnes
        gap: 1, // Réduction de l'espacement entre les items
        padding: 1, // Réduction du padding
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
        backgroundColor: "white",
        marginBottom: 2,
        maxWidth: 500, // Limitation de la largeur totale
      }}
    >
      {allWidgets &&
        allWidgets.length > 0 &&
        allWidgets.map((widget) => (
          <ListItem
            key={widget.id}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 1,
              width: "100%",
              margin: 0,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSelected(widget.component_name)}
                  onChange={() => toggleWidget(widget.component_name)}
                />
              }
              label={widget.name}
              sx={{
                margin: 0,
                whiteSpace: "normal",
                maxWidth: 300,
              }}
            />
          </ListItem>
        ))}
    </Box>
  );
};

export default WidgetSelector;
