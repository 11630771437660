import { useState } from "react";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./dashboard-navbar";
import { DashboardSidebar } from "./dashboard-sidebar";
import { Box } from "@mui/material";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

const ContentContainer = styled("div")(({ theme }) => ({
  flex: "1",
  paddingTop: 50,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
  },
}));

export const DashboardLayout = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openSide, setOpenSide] = useState(false);
  return (
    <DashboardLayoutRoot>
      <DashboardSidebar
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
        openSide={openSide}
        setOpenSide={setOpenSide}
      />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <DashboardNavbar
        onOpenSidebar={() => setIsSidebarOpen(true)}
        open={isSidebarOpen}
        openSide={openSide}
        setOpenSide={setOpenSide}
      />
    </DashboardLayoutRoot>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
