import { useCallback,useEffect, useState } from 'react';
import { Autocomplete, Box, Card, CardHeader, Divider, Grid, Button, Fab, List, ListItem, ListItemText, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';

import { useMounted } from '../../../hooks/use-mounted';
import { useAuth } from '../../../hooks/use-auth';
import { userApi } from '../../../api/user-api';
import { patientsFolderApi } from '../../../api/patients_folder-api';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { auditsApi } from '../../../api/audit-api';
import Swal from 'sweetalert2'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const PatientsFolderBasicDetails = (props) => {
  let { patientsFolder, setPatientsFolder, professionsToModify, setProfessionsToModify, auditsToModify, setAuditsToModify } = props;
  
  const isMounted = useMounted();
  const { user } = useAuth();
  const [modify, setModify] = useState({
    "name": null,
    "tlms_id": null,
    "audits_id": null,
    "program_number": null
  });
  const [modifyText, setModifyText] = useState("")
  const [audits, setAudits] = useState([])
  const [units, setUnits] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const professions = ['Médecin', 'Infirmier', 'Chirurgien-dentiste', 'Masseur-kinésithérapeute', 'Pharmacien', 'Podo-orthésiste', 'Pédicure-podologue', 'Sage-Femme'];
  // const [professionsToModify, setProfessionsToModify] = useState(patientsFolder.patients_file.professions);

  // useEffect(() => {
  //   // setProfessionsToModify(['Médecin', 'Infirmier']);
  //   console.log(patientsFolder.patients_file.professions);
  // }, [professionsToModify]);

  const getAudits = useCallback(async () => {
    try {
      const data = await auditsApi.getAllAudits(user.admin_id)
      // console.log(data)
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  // console.log(patientsFolder.patients_folder_datas)

  // const getAllUnits = useCallback(async () => {
  //   try {
  //     const data = await formationsApi.getAllUnitsSyna(user.admin_id)
  //     if (isMounted()) {
  //       setUnits(data);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMounted]);

  async function handleModify(field, data=null) {
    setModify(modify => ({
      ...modify,
      ['patients_file'[field]]: false})
    );
    let changePatientsFolder = { ...patientsFolder };
    if (data) {
      changePatientsFolder['patients_file'][field] = data;
    } else {
      changePatientsFolder['patients_file'][field] = modifyText;
    }
    setPatientsFolder(changePatientsFolder);
    await patientsFolderApi.modifyPatientsFile(user.admin_id, changePatientsFolder);
  }

  async function handleModifyProfessions(field, data=null) {
    setModify(modify => ({
      ...modify,
      ['patients_file'[field]]: false})
    );
    let changePatientsFolder = { ...patientsFolder };
    if (data) {
      changePatientsFolder['patients_file'][field] = data;
    } else {
      changePatientsFolder['patients_file'][field] = [];
    }
    setPatientsFolder(changePatientsFolder);
    await patientsFolderApi.modifyPatientsFile(user.admin_id, changePatientsFolder);
  }

  async function handleModifyAudits(field, data=null) {
    setModify(modify => ({
      ...modify,
      ['patients_file'[field]]: false})
    );
    let changePatientsFolder = { ...patientsFolder };
    if (data) {
      changePatientsFolder['patients_file'][field] = data;
    } else {
      changePatientsFolder['patients_file'][field] = [];
    }
    setPatientsFolder(changePatientsFolder);
    await patientsFolderApi.modifyPatientsFile(user.admin_id, changePatientsFolder);
  }

  async function handleModifyPatientsFolder(field, index, data=null) {
    // console.log(data)
    setModify(modify => ({
      ...modify,
      ['patients_folder_datas'[field]]: false})
    );
    let changePatientsFolder = { ...patientsFolder };
    if (data) {
      changePatientsFolder['patients_folder_datas'][index][field] = data;
      
    } else {
      changePatientsFolder['patients_folder_datas'][index][field] = null;
    }
    if (field == "required"){
        changePatientsFolder['patients_folder_datas'][index][field] = data;
    }
    setPatientsFolder(changePatientsFolder);
    await patientsFolderApi.modifyPatientsFolder(user.admin_id, changePatientsFolder, index);
  }

  async function handleDelete(id, index, associated_user) {
    // console.log(associated_user)
    if (associated_user.length == 0) {
      Swal.fire({
        title: 'Voulez-vous vraiment supprimer ce dossier patient ?',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // console.log(patientsFolder.patients_folder_datas)
          let items = Array.from(patientsFolder.patients_folder_datas);
          // console.log(id)
          items.splice(index, 1);
          let changePatientsFolder = { ...patientsFolder };
          changePatientsFolder['patients_folder_datas'] = items;
          await patientsFolderApi.deletePatientsFolder(user.admin_id, id)
          setPatientsFolder(changePatientsFolder);
          // let answer = await coursesApi.unlockSecondTurn(user.admin_id, course_id)
          // if (answer.msg == 'unlocked') {
          //   Swal.fire({
          //     title: "Tour débloqué",
          //     icon: 'success',
          //   })
          // } else if (answer.msg == 'impossible unlock') {
          //   Swal.fire({
          //     title: "Impossible de débloquer le tour maintenant",
          //     icon: 'warning',
          //   })
          // } else if (answer.msg == 'already unlock') {
          //   Swal.fire({
          //     title: "Second tour déjà débloqué",
          //     icon: 'error',
          //   })
          // }
        }
      })
    } else {
        Swal.fire({
          title: 'Voulez-vous vraiment supprimer ce dossier patient, il y a des apprenants associés ?',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Annuler'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let items = Array.from(patientsFolder.patients_folder_datas);
            items.splice(index, 1);
            let changePatientsFolder = { ...patientsFolder };
            changePatientsFolder['patients_folder_datas'] = items;
            await patientsFolderApi.deletePatientsFolder(user.admin_id, id)
            setPatientsFolder(changePatientsFolder);
          }
    })
    
  }
}

  useEffect(() => {
    getAudits();
  }, []);

  async function handleAddPatientsFolder() {
    let items = Array.from(patientsFolder.patients_folder_datas);
    // let new_number;
    // if (items.length === 0) {
    //   new_number = 1;
    // }
    // else {
    //   new_number = Number(items[items.length-1]["number"]) + 1;
    // }

    const id = await patientsFolderApi.createPatientsFolder(user.admin_id, patientsFolder.id)
    items.push({ id: id.id.toString(), title: "", description: "", required: false, associated_user: []  });
    let changePatientsFolder = { ...patientsFolder };
    changePatientsFolder['patients_folder_datas'] = items;

    setPatientsFolder(changePatientsFolder);
  }

  return (
    <Card>
      <CardHeader title="Informations"/>
      <Divider/>
      <List disablePadding>
      
        <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Nom du fichier patients
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                {modify.name ?
                <TextField
                  size='small'
                  // focused
                  // autoFocus
                  defaultValue={patientsFolder.patients_file.name}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleModify("name");
                    }
                  }}
                  onBlur={() => {
                    handleModify("name");
                  }}
                  onChange={(e) => setModifyText(e.target.value)}
                />
                : // ELSE
                <Typography
                  onDoubleClick={() => {setModify(modify => ({
                    ...modify,
                    name: true}));
                    setModifyText(patientsFolder.patients_file.name)
                  }}
                  color="textSecondary"
                  variant="body2"
                >
                  {patientsFolder.patients_file.name}
                </Typography>
                }
              </Box>
            )}
          />
        </ListItem>
        <Divider/>
        <ListItem>
        <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 800
            }}
          >
            <Autocomplete
              multiple
              // getOptionLabel={(option) => option}
              options={professions}
              disableClearable
              disableCloseOnSelect
              onChange={(e, value) => {
                // console.log(value);
                setProfessionsToModify(value);
                handleModifyProfessions('professions', value)
              }}
              value={professionsToModify}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Professions"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
            />
          </Box>
        </ListItem>
        <Divider/>
        <ListItem>
        <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 800
            }}
          >
            <Autocomplete
              multiple
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              options={audits}
              disableClearable
              disableCloseOnSelect
              onChange={(e, value) => {
                // console.log(value);
                setAuditsToModify(value);
                handleModifyAudits('epps', value)
              }}
              value={auditsToModify}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Audits"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
          </Box>
        </ListItem>
        <Divider/>
        {patientsFolder.patients_folder_datas.map(({id, title, description, required, associated_user}, index) => {
        return (<Grid
            sx={{
              width:"95%",
            }}
          >
            <Card
              sx={{
                m: 1,
                p: 1,
                width:"100%",
              }}
            >
              <Grid container
                sx={{
                  display:'flex',
                }}
              >
                <Grid item sm={3}
                >
                  <TextField
                    label="Titre"
                    textAlign={"center"}
                    placeholder="Titre"
                    // type="number"
                    value={title}
                    onChange={(event) => {
                    // console.log(event.target.value)
                    handleModifyPatientsFolder("title", index, event.target.value)
                    }}
                  />
                </Grid>
                <Grid item sm={5}
                >
                  <TextField
                    label="Description"
                    textAlign={"center"}
                    placeholder="Description"
                    // type="number"
                    // width={300}
                    value={description}
                    onChange={(event) => {
                    // handleModifyPageNumber(index, event.target.value)
                    handleModifyPatientsFolder("description", index, event.target.value)
                    }}
                  />
                </Grid>
                <Grid
                  item sm={2}
                >
                <MenuItem>
                  <Typography>Obligatoire (non / oui)</Typography>
                  <Switch
                    checked={required}
                    onChange={(event) => {
                      handleModifyPatientsFolder("required", index, event.target.checked)
                    }}
                  />
                  {/* <Tooltip title="Contrôle les tâches cron"><Typography>Envoi de mails automatique</Typography></Tooltip> */}
                  
                </MenuItem>
                </Grid>
                <Grid
                  item sm={2}
                >
                  <Box textAlign={"center"}>
                    <Button sx={{
                      textAlign:"center"
                    }}
                    onClick={() => {
                      handleDelete(id, index, associated_user)
                    }}
                    >
                      <DeleteOutlineIcon/>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )})}
        
         
      </List>
      <Fab
          sx={{
            top: 'auto',
            right: 170,
            bottom: 30,
            left: 'auto',
            backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
            color: 'white',
            position: 'fixed',
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 6px 7px -3px",
          }}
          size="large"
          variant="extended"
          color="primary"
          aria-label="add"
          onClick={() => handleAddPatientsFolder()}
        >
          <AddIcon />
          Dossier patients
        </Fab>
    </Card>
  );
};
