import { Avatar, Box, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Typography, Badge, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/fr'; 
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';

moment.locale('fr')


const badgeStyles = makeStyles((theme) => ({
    badge: {
      width: 25,
      height: 25,
      borderRadius: '50%',
      backgroundColor: "#1c2531",
      color: "white",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
    },
}));
  
function CircularBadge({ number }) {
    const classes = badgeStyles();

    return (
        <Badge
        badgeContent={number}
        overlap="circle"
        classes={{ badge: classes.badge }}
        sx={{ mr: 3 }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    scrollbar: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  }));


function ConversationsList(props) {
  const { 
    conversations, 
    setConversations, 
    isLoading,
    setIsLoading,
    selectedIndex, 
    setSelectedIndex, 
    setSelectedId, 
    setSelectedLearnerId, 
    searchValue, 
    setSearchValue, 
    readMessages, 
    formatDate,
    getConversationMessages
    } = props
  const theme = useTheme();
  const [extendSearchbar, setExtendSearchbar] = useState(false)
  const classes = useStyles()

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
        <Grid 
            item 
            xs={3}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between',  borderRight: `1px solid ${theme.palette.divider}`, borderTop: `1px solid ${theme.palette.divider}`,  borderBottom: `1px solid ${theme.palette.divider}`}}>
                {!extendSearchbar ? (
                <Typography 
                    sx={{ 
                        flex: 1, 
                        textAlign: 'center',
                        fontSize: 18, 
                        fontWeight: 600,
                        py: 1.31
                    }}
                >
                    Conversations 
                </Typography>
                ) : (
                <TextField 
                    id="recherche" 
                    label="Chercher un apprenant"
                    fullWidth={true}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    variant="standard" />
                )
                }
                {!extendSearchbar ? (
                    <IconButton 
                        sx={{ mt: 0.5 }}
                        aria-label="extend"
                        onClick={() => setExtendSearchbar(true)}
                    >
                        <SearchIcon />
                    </IconButton>
                    ) : (
                    <IconButton 
                        sx={{ mt: 0.5 }}
                        aria-label="close"
                        onClick={() => {
                            setExtendSearchbar(false)
                            setSearchValue("")
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    )
                }
            </Box>
            <List
                className={classes.scrollbar}
                sx={{ 
                    width: '100%', 
                    height: '600px',
                    borderRight: `1px solid ${theme.palette.divider}`,
                }}
            >
                {isLoading ? (
                <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress /> 
                </Box>
                ) : 
                conversations.length > 0 ? conversations?.map((conv, index) => (
                    <Box sx={{ mt: -1, backgroundColor: '#f5f7f7' && !conv.is_read }}>
                        <ListItemButton
                            selected={selectedIndex === index}
                            onClick={(event) => {
                                handleListItemClick(index)
                                setSelectedId(conv.id)
                                setSelectedLearnerId(conv.learnerDetails.id)
                                if (conversations[index].numberUnreadMessages > 0) {
                                    const updateConversations = [...conversations]
                                    updateConversations[index].numberUnreadMessages = 0
                                    setConversations(updateConversations)
                                    readMessages(conv.id)

                                    const unreadMessages = parseInt(localStorage.getItem('messages-non-lus'), 10) || 0;
            const newUnreadCount = unreadMessages - conversations[index].numberUnreadMessages;
            localStorage.setItem('messages-non-lus', Math.max(newUnreadCount, 0));

window.dispatchEvent(new Event('messagesNonLusUpdated')); 
                                }
                                getConversationMessages(conv.id, conv.learnerDetails.fullname, conv.learnerDetails.avatar)
                            }}
                        >
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        alt={conv.learnerDetails.fullname} 
                                        src={conv.learnerDetails.avatar} 
                                        sx={{ width: 60, height: 60 }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ ml: 2 }}
                                    primary={
                                        <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography sx={{ fontSize: 17, fontWeight: conv.numberUnreadMessages > 0 && 'bold' }}>
                                                {conv.learnerDetails.fullname}
                                            </Typography>
                                        </Box>
                                    }
                                    secondary={
                                        <>
                                            <Box sx={{ display: 'flex', minHeight: '45px', justifyContent: 'space-between'}}>
                                                <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary, fontStyle: conv.lastMessage == null && 'italic', fontWeight: conv.numberUnreadMessages > 0 && 'bold' }}>
                                                    {conv.lastMessage !== null ? 
                                                        (conv.lastMessage.type_author === 'formateur' ? 
                                                        `Vous : ${conv.lastMessage?.content.length > 45 ? conv.lastMessage?.content.substring(0, 75) + "..." : conv.lastMessage?.content}`  : 
                                                        `${conv.lastMessage?.content.length > 40 ? conv.lastMessage?.content.substring(0, 41) + "..." : conv.lastMessage?.content}`)
                                                        :
                                                        "Nouvelle conversation"
                                                    }
                                                </Typography>
                                                {conv.numberUnreadMessages > 0 && <CircularBadge number={conv.numberUnreadMessages} />}
                                            </Box>
                                            <Typography sx={{ fontSize: 14, textAlign: 'end', fontStyle: 'italic', fontWeight: conv.numberUnreadMessages > 0 && 'bold'}}>
                                                {conv.lastMessage !== null && formatDate(conv.lastMessage.sent_at)}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                        </ListItemButton>
                        {index !== conversations.length - 1 && <Divider sx={{ mx: 1, mb: 1 }} />}
                    </Box>
                )) : (
                    <Box sx={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ fontStyle: 'italic' }}>Aucune conversation</Typography>
                    </Box>
                )}
            </List>
        </Grid>
  )
}

export default ConversationsList