import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';

const MeanByAudit = (props) => {
  const { time, isLoadingTime } = props;
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      }
    },
    colors: ['#13affe', '#fbab49'],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    legend: {
      labels: {
        colors: theme.palette.text.secondary
      },
      show: true
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '70%'
      }
    },
    stroke: {
      colors: ['transparent'],
      show: true,
      width: 3
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      
      axisBorder: {
        show: true,
        color: theme.palette.divider
      },
      axisTicks: {
        show: true,
        color: theme.palette.divider
      },
      categories: time.map((el) => el.name),
      // categories: time.map((el) => el.name.slice(0,15) + '...'),
      labels: {
        style: {
          fontSize: '12px',
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      labels: {
        style: {
          fontSize: '13px',
          colors: theme.palette.text.secondary
        }
      },
      title: {
        text: 'Heure(s)'
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px'
      },
      x : {
        formatter: (value, obj) => {
          return time[obj.dataPointIndex].name;  // Utilisez time[value.seriesIndex].name ici
        }
      }
    }
  };

  const chartSeries = [
    {
      data: time.map((el) => el.time),
      name: 'Moyenne d\'heure passée'
    }
  ];
  return (
    <Card sx={{ width : '1500px', height : '500px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: 2,
      border:'1px solid'
    }}>
      <CardHeader
        title= {
          <Grid>
            <Typography
              variant="h4"
            >
              Temps moyen par audit
            </Typography>
          </Grid>
        }
      />
      <Divider/>
      <CardContent>
        {isLoadingTime ? 
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
          <CircularProgress 
            size={150} 
          />
        </Box> :
        <Chart
          height={500}
          options={chartOptions}
          series={chartSeries}
          type="bar"
        />}
      </CardContent>
    </Card>
  );
};

export default MeanByAudit;