class ParamsApi {
    async getParamAutomaticMail(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMail/get/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async setParamAutomaticMail(admin_id, isAutomaticMail) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMail/set/`
        const values = {
            "admin_id": admin_id,
            "is_automatic_mail": isAutomaticMail,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getParamAutomaticMailToCustomer(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailToCustomer/get/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async setParamAutomaticMailToCustomer(admin_id, isAutomaticMail) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailToCustomer/set/`
        const values = {
            "admin_id": admin_id,
            "is_automatic_mail_to_customer": isAutomaticMail,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    

// /////////////////////////////////////////////////////////////////////////////////////////
async getParamAutomaticMailAdminNewDevice(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailAdminNewDevice/get/`
    const values = {
        "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};
async setParamAutomaticMailAdminNewDevice(admin_id, automatic_mails_AdminNewDevice) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailAdminNewDevice/set/`
    const values = {
        "admin_id": admin_id,
        "automatic_mail_AdminNewDevice": automatic_mails_AdminNewDevice,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};


// 
async getParamAutomaticMailUserNewDevice(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailUserNewDevice/get/`
    const values = {
        "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};
async setParamAutomaticMailUserNewDevice(admin_id, automatic_mail_UserNewDevice) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailUserNewDevice/set/`
    const values = {
        "admin_id": admin_id,
        "automatic_mail_UserNewDevice": automatic_mail_UserNewDevice,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};

// 


async getParamAutomaticMailAdminFirstLogin(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailAdminFirstLogin/get/`
    const values = {
        "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};
async setParamAutomaticMailAdminFirstLogin(admin_id, automatic_mails_AdminFirstLogin) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailAdminFirstLogin/set/`
    const values = {
        "admin_id": admin_id,
        "automatic_mails_AdminFirstLogin": automatic_mails_AdminFirstLogin,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};

// 

async getParamAutomaticMailUserFirstLogin(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailUserFirstLogin/get/`
    const values = {
        "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};
async setParamAutomaticMailUserFirstLogin(admin_id, automatic_mails_UserFirstLogin) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/config/automaticMailUserFirstLogin/set/`
    const values = {
        "admin_id": admin_id,
        "automatic_mails_UserFirstLogin": automatic_mails_UserFirstLogin,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        }
    });
    const json = await resp.json();
    return Promise.resolve(json);
};
}
export const paramsApi = new ParamsApi();