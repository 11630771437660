import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { Delete as DeleteIcon } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";

export const NotificationsPopover = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [wsConnection, setWsConnection] = useState(null);
  const { user } = useAuth();
  const [coursesWithoutFeedbacksNumber, setCoursesWithoutFeedbacksNumber] =
    useState(0);
  const [firstTurnData, setFirstTurnData] = useState([]);
  const [secondTurnData, setSecondTurnData] = useState([]);
  // const [integratedProgramData, setIntegratedProgramData] = useState([]);
  // const [integratedProgramDataSecondTurn, setIntegratedProgramDataSecondTurn] = useState([]);
  const prevCoursesWithoutFeedbacksNumberRef = useRef(null);
  const audioRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const openMenu = Boolean(anchorEl);
  const openSecondMenu = Boolean(anchorEl2);

  const handleClick = (event, notification) => {
    setAnchorEl(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleClickSecondAnchor = (event, notification) => {
    setAnchorEl2(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const handleCloseSecondAnhor = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    // Charger le fichier audio
    audioRef.current = new Audio("static/notification.mp3");
  }, []);

  const playNotificationSound = () => {
    // Vérifier si l'audio est déjà chargé
    if (audioRef.current.readyState === 4) {
      // Le fichier audio est prêt à être lu, jouer le son de notification
      audioRef.current.play();
    } else {
      // Le fichier audio n'est pas encore chargé, attendre jusqu'à ce qu'il le soit
      audioRef.current.oncanplaythrough = () => {
        audioRef.current.play();
      };
    }
  };

  const sendNotificationWindows = () => {
    if (Notification.permission === "granted") {
      new Notification("Nouvelle notification de Kalanke", {
        body: `Vous avez ${coursesWithoutFeedbacksNumber.all} nouvelle(s) notification(s)`,
        icon: process.env.REACT_APP_TAB_LOGO,
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification("Nouvelle notification de Kalanke", {
            body: `Vous avez ${coursesWithoutFeedbacksNumber.all} nouvelle(s) notification(s)`,
            icon: process.env.REACT_APP_TAB_LOGO,
          });
        }
      });
    }
  };

  useEffect(() => {
    // Connexion au WebSocket lors du montage du composant
    const url =  process.env.REACT_APP_WS_URL + `subdomain/${globalThis.localStorage.getItem('tenant')}/socket-server/?user_id=${user.admin_id}`;
    // const url = `ws://127.0.0.1:7000/ws/socket-server/?user_id=${user.admin_id}`;
    const cnxSocket = new WebSocket(url);
    cnxSocket.onopen = () => {
      setWsConnection(cnxSocket);
      cnxSocket.send(
        JSON.stringify({
          message: "Notifications Update",
          user_id: user.admin_id,
        })
      );
    };
    cnxSocket.onmessage = (e) => {
      cnxSocket.send(
        JSON.stringify({
          message: "Notifications Update",
          user_id: user.admin_id,
        })
      );
      let data = JSON.parse(e.data);
      // setCoursesWithoutFeedbacks(data.data);
      setFirstTurnData(data.data.first_turn);
      setSecondTurnData(data.data.second_turn);
      // setIntegratedProgramData(data.data.integrated_program_first_turn);
      // setIntegratedProgramDataSecondTurn(data.data.integrated_program_second_turn)
      setCoursesWithoutFeedbacksNumber(data.data_length);
    };

    // fermer la connexion WebSocket lors du démontage du composant
    return () => {
      if (wsConnection) {
        wsConnection.close();
      }
    };
  }, [open]);

  useEffect(() => {
    // Vérifier si le nombre de notifications a augmenté
    if (
      prevCoursesWithoutFeedbacksNumberRef.current !== null &&
      coursesWithoutFeedbacksNumber.all >
        prevCoursesWithoutFeedbacksNumberRef.current.all
    ) {
      // Jouer le son de notification
      playNotificationSound();
      sendNotificationWindows();
    }
    // Mettre à jour la valeur précédente
    prevCoursesWithoutFeedbacksNumberRef.current =
      coursesWithoutFeedbacksNumber;
  }, [coursesWithoutFeedbacksNumber]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClick = (notificationId, turn) => {
    if (wsConnection) {
      // Envoyer un message WebSocket avec l'ID de la notification et le type de message
      wsConnection.send(
        JSON.stringify({
          message: "MarkNotificationAsSeen",
          notification_id: notificationId,
          turn: turn,
        })
      );
      if (turn == "turn_1") {
        setAnchorEl(null);
      } else if (turn == "turn_2") {
        setAnchorEl2(null);
      }
    }
  };

  const markNotificationAsRead = (notificationId, turn) => {
    if (wsConnection) {
      // Envoyer un message WebSocket avec l'ID de la notification et le type de message
      wsConnection.send(
        JSON.stringify({
          message: "MarkNotificationAsRead",
          notification_id: notificationId,
          turn: turn,
        })
      );
      if (turn == "turn_1") {
        setAnchorEl(null);
      } else if (turn == "turn_2") {
        setAnchorEl2(null);
      }
    }
  };

  const MarkNotificationsAsNotCountable = () => {
    setOpen(true);
    if (wsConnection && firstTurnData && secondTurnData) {
      const dataToSend = {
        firstTurnData: firstTurnData,
        secondTurnData: secondTurnData,
      };
      wsConnection.send(
        JSON.stringify({
          message: "MarkNotificationAsSeenAndNotCountable",
          data: dataToSend,
        })
      );
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          position: "relative",
          marginRight: 1,
          color: "#8282CA"
        }}
      >
        <NotificationsIcon
          sx={{ fontSize: 40 }}
          onClick={() => MarkNotificationsAsNotCountable()}
        />
        {!open &&
        coursesWithoutFeedbacksNumber.all &&
        coursesWithoutFeedbacksNumber.all > 0 ? ( // Ajoutez cette condition
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 20,
              height: 20,
              backgroundColor: "#32CD32", // Couleur de la bulle de coursesWithoutFeedbacks
              borderRadius: "50%",
              border: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff", // Couleur du texte
            }}
          >
            {coursesWithoutFeedbacksNumber.all}
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: "25%", maxHeight: '50%' },
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography color="textPrimary" variant="h6">
            Notifications
          </Typography>
        </Box>
        <Divider />
        {
        (firstTurnData &&
        secondTurnData &&
        firstTurnData.length === 0 &&
        secondTurnData.length === 0)
        ||
        (firstTurnData == null || secondTurnData == null)
        
        ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              Il n&apos;y a pas de notifications
            </Typography>
          </Box>
        ) : (
          <>
            {firstTurnData &&
              firstTurnData.map((notification) => (
                <Box
                  key={notification.id}
                  backgroundColor={(theme) =>
                    theme.palette.mode === "dark" ? "black" : "white"
                  }
                >
                  {notification.has_feedback_turn_1 === false && (
                    <>
                      <ListItem
                        divider
                        key={notification.id}
                        sx={{
                          backgroundColor:
                            notification.is_first_turn_read === false
                              ? "#EFEFEF"
                              : "#ffffff",
                          display: "flex",
                          flexDirection: "row", // Inversion de l'ordre des éléments enfants
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: "primary.main",
                              color: "primary.contrastText",
                            }}
                          >
                            <FeedbackIcon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              color={(theme) =>
                                theme.palette.mode === "dark"
                                  ? "black"
                                  : "textPrimary"
                              }
                              variant="subtitle2"
                              fontWeight="bold"
                            >
                              Nouveau Feedback à faire pour les axes
                              d'améliorations :
                            </Typography>
                          }
                          secondary={
                            <Box>
                              <Box
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "black"
                                    : "textPrimary"
                                }
                              >
                                {"L'apprenant "}
                                {notification.firstname} {notification.lastname}
                                {" pour l'audit "}
                                <Link
                                  component="a"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    window.location.href = `/dashboard/customers/${notification.users_id}`;
                                  }}
                                  color="inherit"
                                  underline="none"
                                  style={{ color: "black" }}
                                >
                                  {notification.audit_name || ""}
                                </Link>
                              </Box>
                            </Box>
                          }
                        />
                        <IconButton
                          aria-label="options"
                          onClick={(event) =>
                            handleClick(event, notification.id)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </ListItem>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseAnchor}
                      >
                        <MenuItem
                          onClick={() => {
                            markNotificationAsRead(
                              selectedNotification,
                              "turn_1"
                            );
                          }}
                        >
                          <ListItemIcon>
                            <MarkChatReadIcon />
                          </ListItemIcon>
                          <ListItemText primary="Marquer comme lu" />
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleNotificationClick(
                              selectedNotification,
                              "turn_1"
                            )
                          }
                        >
                          <ListItemIcon>
                            <DeleteIcon sx={{ color: "#951212" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Supprimer la notification"
                            sx={{ color: "#951212" }}
                          />
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>
              ))}

            {secondTurnData &&
              secondTurnData.map((notification) => (
                <Box key={notification.id}>
                  {notification.has_feedback_turn_2 === false && (
                    <>
                      <ListItem
                        divider
                        key={notification.id}
                        sx={{
                          backgroundColor:
                            notification.is_second_turn_read === false
                              ? "#EFEFEF"
                              : "#ffffff",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: "primary.main",
                              color: "primary.contrastText",
                            }}
                          >
                            <FeedbackIcon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              color={(theme) =>
                                theme.palette.mode === "dark"
                                  ? "black"
                                  : "textPrimary"
                              }
                              variant="subtitle2"
                              fontWeight="bold"
                            >
                              Nouveau Feedback à faire pour le tour 2 :
                            </Typography>
                          }
                          secondary={
                            <Box
                              onClick={(event) => {
                                event.preventDefault();
                                window.location.href = `/dashboard/customers/${notification.users_id}`;
                              }}
                            >
                              <Box
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "black"
                                    : "textPrimary"
                                }
                              >
                                {"L'apprenant "}
                                {notification.firstname} {notification.lastname}
                                {" pour l'audit "}
                                <Link
                                  component="a"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    window.location.href = `/dashboard/customers/${notification.users_id}`;
                                  }}
                                  color="inherit"
                                  underline="none"
                                  style={{ color: "black" }}
                                >
                                  {notification.audit_name || ""}
                                </Link>
                              </Box>
                            </Box>
                          }
                        />
                        <IconButton
                          aria-label="options"
                          onClick={(event) =>
                            handleClickSecondAnchor(event, notification.id)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </ListItem>
                      <Menu
                        anchorEl={anchorEl2}
                        open={openSecondMenu}
                        onClose={handleCloseSecondAnhor}
                      >
                        <MenuItem
                          onClick={() => {
                            markNotificationAsRead(
                              selectedNotification,
                              "turn_2"
                            );
                          }}
                        >
                          <ListItemIcon>
                            <MarkChatReadIcon />
                          </ListItemIcon>
                          <ListItemText primary="Marquer comme lu" />
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleNotificationClick(
                              selectedNotification,
                              "turn_2"
                            )
                          }
                        >
                          <ListItemIcon>
                            <DeleteIcon sx={{ color: "#951212" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Supprimer la notification"
                            sx={{ color: "#951212" }}
                          />
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>
              ))}
          </>
        )}
      </Popover>
    </>
  );
};
