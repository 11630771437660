import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from '../../modules/login_page/index';

const AuthGuard = ({ children }) => {
  const auth = useAuth(); // Get authentication state
  const location = useLocation(); // Get current route location
  const navigate = useNavigate(); // For navigation
  const [requestedLocation, setRequestedLocation] = useState(null); // Track redirected path

  useEffect(() => {
    if (!auth.isAuthenticated) {
      // Redirect to the tenant-specific login page
      const tenant = globalThis.localStorage.getItem('tenant');
      if (tenant) {
        navigate(`/${tenant}`, { replace: true });
      }
      else{
        navigate(`/`, { replace: true });
      }
    }
  }, [auth.isAuthenticated, navigate]);

  // If the user isn't authenticated, show the Login component
  if (!auth.isAuthenticated) {
    return <Login />;
  }

  // Handle redirect after successful login to the initially requested path
  if (requestedLocation && location.pathname !== requestedLocation) {
    const pathToRedirect = requestedLocation;
    setRequestedLocation(null); // Clear stored path
    return <Navigate to={pathToRedirect} replace />;
  }

  // Store the requested location if not already saved
  if (!requestedLocation && location.pathname !== '/') {
    setRequestedLocation(location.pathname);
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
