import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../hooks/use-auth";
import { gtm } from "../../lib/gtm";

import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { useMounted } from "../../hooks/use-mounted";
import { topicsApi } from "../../api/topic-api";
import { auditsApi } from "../../api/audit-api";

import { AvancementSessions } from "../../components/dashboard/statistiques/avancementSessions";

import { MeanByAudit } from "../../components/dashboard/statistiques/mean-audit";
import { LastActivities } from "../../components/dashboard/statistiques/last-activities";
import DashboardFormateur from "./dashbord-formateur";
import DashboardAdmin from "./dashboard-admin";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WidgetSelector from "./widgets/widget-selector";

const Dashboard = () => {
  const isMounted = useMounted();
  const [progression, setProgression] = useState([]);
  const [meanTime, setMeanTime] = useState([]);
  const [isLoadingTime, setIsLoadingTime] = useState(true);
  const [isSelectingWidgets, setIsSelectingWidgets] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { user } = useAuth();

  const getMeanTime = useCallback(async () => {
    try {
      const data = await auditsApi.meanTime(user.admin_id);
      if (isMounted()) {
        setMeanTime(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingTime(false);
    }
  }, [isMounted]);

  const getProgression = useCallback(async () => {
    try {
      const data = await topicsApi.getProgression(user.admin_id);
      if (isMounted()) {
        setProgression(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getProgression();
    getMeanTime();
    gtm.push({ event: "page_view" });
  }, []);

  const handleToggleWidgetMenu = () => {
    setIsSelectingWidgets(!isSelectingWidgets);
  };

  const handleToggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setIsSelectingWidgets(!isSelectingWidgets);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
          height: "110%",
          width:'200%',
          // border: '1px solid',
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "#37394A"
              : isSelectingWidgets
              ? "white"
              : "#F1F1F9",
        }}
      >
        <Container maxWidth={false} style={{
          //  border: '1px solid',
           width:'100%'}}>
          <Box sx={{ mb: 5 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "white" : "black",
                    fontFamily: "Montserrat",
                    ml: 1,
                    color: "#484848",
                  }}
                  variant="h4"
                >
                  TABLEAU DE BORD
                </Typography>
              </Grid>

              {user.id_service === 0 && (
                <>
                  <Grid item>
                    <IconButton onClick={handleToggleEditMode} color="primary">
                      {isEditMode ? <VisibilityIcon /> : <EditOutlinedIcon />}
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {user.id_service === 0 ? (
            <>
              <DashboardAdmin
                progression={progression}
                meanTime={meanTime}
                isLoadingTime={isLoadingTime}
                admin_id={user.admin_id}
                isSelectingWidgets={isSelectingWidgets}
                isEditMode={isEditMode}
                handleToggleEditMode={handleToggleEditMode}
              />
            </>
          ) : (
            <>
              <DashboardFormateur progression={progression} />
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
