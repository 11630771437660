import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, InputLabel, TextField, Typography } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/styles';

// Style object for customizing form fields
const useTextFieldStyles = makeStyles({
  root: {
    '& label': {
      color: '#251E74',
      paddingLeft: '1rem',
      fontFamily: '"Montserrat", sans-serif'
    },
    '& label.Mui-focused': {
      color: '#251E74'
    },
    '& .MuiInput-underline:before': {
      borderColor: '#251E74'
    },
    '& .MuiInput-underline:after': {
      borderColor: '#251E74'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: '#251E74'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#6b0c01',
      fontWeight: 'bold'
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: '#251E74'
    },
    '& input:-webkit-autofill': {
      'transition': 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
      'background-color': 'initial !important',
      'color': 'initial !important'
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#251E74"
      },
      "&:hover fieldset": {
        borderColor: "#5d5db7"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3B2755"
      },
      "&.Mui-error fieldset": {
        borderColor: "#FF0000"
      }
    }
  }
});

// Login form using Formik
export const JWTLogin = (props) => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth();
  const classes = useTextFieldStyles();
  const { handleForgottenPassword, setLoginError } = props;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("L'adresse mail doit être valide")
        .max(255)
        .required("Adresse mail requise"),
      password: Yup.string().max(255).required("Mot de passe requis"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const res = await login(values.email, values.password);

        if (isMounted() && res.done) {
          setLoginError(null);
          const targetRoute = res.first_login === true || res.new_device===true
            ?`/${globalThis.localStorage.getItem('tenant')}/confirm_email` 
            : `/${globalThis.localStorage.getItem('tenant')}/dashboard`;

          navigate(targetRoute, { replace: true });
        } else {
          throw new Error("Email ou mot de passe incorrect(s)");
        }
      } catch (err) {
        console.error(err);
        const errorMessage = err.message.includes("Failed to fetch")
          ? "Connexion impossible : problème au niveau du serveur"
          : err.message || "Une erreur inattendue s'est produite";

        if (isMounted()) {
          helpers.setErrors({ submit: errorMessage });
          setLoginError(errorMessage);
        }
      } finally {
        if (isMounted()) {
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} {...props}>
      <InputLabel sx={{ mt: 3, mb: 1, color: 'black', fontWeight: 'medium', fontFamily: 'Montserrat' }}>
        Adresse e-mail
      </InputLabel>
      <TextField
        variant="outlined"
        error={Boolean(formik.touched.email && formik.errors.email)}
        size="small"
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        name="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        className={classes.root}
        sx={{
          '& .MuiOutlinedInput-root fieldset': {
            borderColor: formik.errors.email ? '#FF0000' : '#251E74',
          },
        }}
      />

      <InputLabel sx={{ mt: 3, mb: 1, color: 'black', fontWeight: 'medium', fontFamily: 'Montserrat' }}>
        Mot de passe
      </InputLabel>
      <TextField
        variant="outlined"
        error={Boolean(formik.touched.password && formik.errors.password)}
        size="small"
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        name="password"
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
        className={classes.root}
        sx={{
          '& .MuiOutlinedInput-root fieldset': {
            borderColor: formik.errors.password ? '#FF0000' : '#251E74',
          },
        }}
      />

      {formik.errors.submit && (
        <FormHelperText error sx={{ mt: 2 }}>
          {formik.errors.submit}
        </FormHelperText>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{ cursor: 'pointer', color: '#A3A3A3', fontFamily: 'Montserrat', fontSize: 14 }}
          onClick={handleForgottenPassword}
        >
          Mot de passe oublié ?
        </Typography>
      </Box>

      <Button
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        sx={{
          mt: 3,
          backgroundColor: '#251E74',
          color: 'white',
          '&:hover': {
            backgroundColor: 'white',
            color: '#251E74',
          },
        }}
      >
        Se connecter
      </Button>
    </form>
  );
};
