import {
    Box,
    Card,
    Container,
    Divider,
    Grid,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    IconButton,
    TableRow,
    TextField,
    Typography,
    CircularProgress,
    Tooltip,
    Avatar,
    Autocomplete,
    Select,
    MenuItem,
  } from "@mui/material";
import { useMounted } from "../../../hooks/use-mounted";
import { useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { userApi } from "../../../api/user-api";
import { useCallback } from "react";
import { useEffect } from "react";
import { Scrollbar } from "../../../components/scrollbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import BlockIcon from "@mui/icons-material/Block";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassFullTwoToneIcon from "@mui/icons-material/HourglassFullTwoTone";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import moment from 'moment';
import 'moment/locale/fr';

const feedbackOptions = ["À venir", "En attente", "Finis"]

// Tableau des apprenants et des feedbacks à leur envoyer
const FeedBacks = (props) => {
  const {
    customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;
  const isMounted = useMounted();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [pagin, setPagin] = useState(0);
  const [match, setMatch] = useState(false);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0);
  const [limit, setLimit] = useState(25);
  const [selectedStartSession, setSelectedStartSession] = useState(null)
  const [selectedEndSession, setSelectedEndSession] = useState(null)
  const [selectedFeedback, setSelectedFeedback] = useState(null)
  const [startSessions, setStartSessions] = useState([]);
  const [endSessions, setEndSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [isFilteredByFeedback, setIsFilteredByFeedback] = useState(false)

  const { user } = useAuth();
  const [audit, setAudit] = useState(user && user.epps.length > 0 ? user.epps[0] : null)

  // Gestion du nombre d'apprenants affichés
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPagin(0);
    handleCustomers(searchName, pagin, parseInt(event.target.value, 10), selectedStartSession, selectedEndSession, selectedFeedback);
  };

  // Gestion du changement de page
  const handlePageChange = (event, newPage) => {
    setPagin(newPage);
    handleCustomers(searchName, newPage, limit, selectedStartSession, selectedEndSession, selectedFeedback);
  };

  // Appel API pour récupérer les apprenants
  const handleCustomers = useCallback(async (searchName, pagin, limit, start_session = null, end_session = null, feedbackFilter = null) => {
    try {
      const data = await userApi.searchUsersTrainer(
        user.admin_id,
        searchName,
        pagin,
        limit,
        audit ? audit.id : null,
        start_session,
        end_session,
        feedbackFilter ? (feedbackFilter == "En attente" ? feedbackFilter : feedbackFilter.toLowerCase()) : null
      );
      
      if (data.search_results.length > 0) {
        setCustomers(data.search_results);
        setTotalNumberOfUsers(data.users_number);
        setMatch(true);
        setIsLoading(false)
      } else {
        setCustomers(data.search_results);
        setTotalNumberOfUsers(data.users_number);
        setMatch(false);
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      console.error(err);
    }
  });

  // Appel API pour récupérer les dates des sessions
  const getSessionDates = async () => {
    try {
      const sessionDates = await userApi.getSessionDates(user.admin_id, audit ? audit.id : null)
      const formattedStartDates = sessionDates.start_sessions.map((date) => moment(date).format('DD/MM/YYYY'))
      const formattedEndDates = sessionDates.end_sessions.map((date) => moment(date).format('DD/MM/YYYY'))
      setStartSessions(formattedStartDates)
      setEndSessions(formattedEndDates)
    } catch (err) {
      console.error(err)
    }
  }

  // Gestion de l'appel API pour filtrer selon les noms en fonction de la recherche
  useEffect(() => {
    if (searchName.length >= 3) {
      setPagin(0);
      handleCustomers(searchName, 0, limit, selectedStartSession, selectedEndSession, selectedFeedback);
    } else if (searchName === "") {
      setPagin(0);
      handleCustomers(searchName, 0, limit, selectedStartSession, selectedEndSession, selectedFeedback);
    }
  }, [searchName]);

  useEffect(() => {
    setIsLoading(true)
    handleCustomers(searchName, pagin, limit, selectedStartSession, selectedEndSession, selectedFeedback);
  }, [searchName, pagin, limit, audit, selectedCustomers]);

  useEffect(() => {
    if (selectedCustomers.length && selectedFeedback === null) {
      setSelectedCustomers([]);
    }
  }, [customers, audit]);

  useEffect(() => {
    getSessionDates()
  }, [audit]);

  useEffect(() => {
    if (!selectedStartSession && !selectedEndSession) {
      handleCustomers(searchName, pagin, limit, selectedStartSession, selectedEndSession, selectedFeedback)
    }
  }, [isLoading]);


  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography variant="h4" sx={{fontFamily: "Montserrat"}}>Apprenants</Typography>
              </Grid>
            </Grid>
          </Box>
            <Card>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 3,
                }}
              >
                {
                  audit !== null &&
                  <Box
                    sx={{
                      m: 1,
                    }}
                  >
                  <Select
                    value={audit?.id}
                    name={audit?.name}
                    onChange={(e) => {
                      const newAudit = {
                        id: e.target.value,
                        name: e.target.innerText
                      }
                      setAudit(newAudit)
                      setIsLoading(true)
                    }}
                    inputProps={{ 'aria-label': 'Select' }}
                  >
                    {user.epps.map((epp) => (
                      <MenuItem value={epp.id}>{epp.name}</MenuItem>
                    ))}
                  </Select>
                  </Box>
                }
                
                <Box sx={{display: 'flex'}}>
                  <Box
                    sx={{
                      m: 1,
                    }}
                  >
                    <TextField
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Chercher un apprenant"
                    />
                  </Box>
                  { startSessions?.length !== 0 &&
                    <Box
                    sx={{
                      m: 1,
                      width: 200
                    }}
                  >
                    <Autocomplete 
                      value={selectedStartSession}
                      onChange={(event, newValue) => {
                        setSelectedStartSession(newValue)
                        setIsLoading(true)
                        handleCustomers(searchName, pagin, limit, newValue, selectedEndSession, selectedFeedback)
                      }}
                      // onBlur={}
                      options={startSessions}
                      noOptionsText="Aucune option"
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          label="Début de session" 
                        />}
                    />
                  </Box>
                  }
                  { endSessions?.length !== 0 &&
                    <Box
                    sx={{
                      m: 1,
                      width: 200
                    }}
                  >
                    <Autocomplete 
                      value={selectedEndSession}
                      onChange={(event, newValue) => {
                        setSelectedEndSession(newValue)
                        setIsLoading(true)
                        handleCustomers(searchName, pagin, limit, selectedStartSession, newValue, selectedFeedback)
                      }}
                      // onBlur={}
                      options={endSessions}
                      noOptionsText="Aucune option"
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          label="Fin de session" 
                        />}
                    />
                  </Box>
                  }
                  { endSessions?.length !== 0 &&
                    <Box
                    sx={{
                      m: 1,
                      width: 200
                    }}
                  >
                    <Autocomplete 
                      value={selectedFeedback}
                      onChange={(event, newValue) => {
                        setSelectedFeedback(newValue)
                        setIsLoading(true)
                        handleCustomers(searchName, pagin, limit, selectedStartSession, selectedEndSession, newValue)                 
                      }}
                      options={feedbackOptions}
                      noOptionsText="Aucune option"
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          label="Feedbacks" 
                        />}
                    />
                  </Box>
                  }
                </Box>
              </Box>
              {isLoading ? 
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <CircularProgress />
                    </Box>
                      :
              (<Scrollbar>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell >
                        <Typography
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Nom
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Email
                        </Typography>
                      </TableCell>
                      <TableCell >
                        <Typography
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Métier
                        </Typography>
                      </TableCell>
                      <TableCell >
                        <Typography
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Début de session
                        </Typography>
                      </TableCell>
                      <TableCell >
                        <Typography
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Fin de session
                        </Typography>
                      </TableCell>
                      <TableCell >
                        <Typography
                          // sx={{ mx: 3 }}
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          FeedBacks
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    customers.length === 0 ? (
                      <Typography color="inherit" size={150} sx={{marginLeft:'20%', marginTop:'10%'}}>
                        Pas d'apprenants
                      </Typography>
                    ) : (
                  <TableBody>
                    {customers.map((customer) => (
                      <TableRow hover key={customer.id} sx={{pl: 2 }}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <RouterLink
                                to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/customers/${customer.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  color={(theme) =>
                                    theme.palette.mode === "dark"
                                      ? process.env.REACT_APP_SECONDARY_COLOR
                                      : process.env.REACT_APP_PRIMARY_COLOR
                                  }
                                  fontWeight="Bold"
                                  fontSize={14}
                                >
                                  {customer.firstname} {customer.lastname}
                                </Typography>
                              </RouterLink>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <Typography
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#F5F6FC"
                                    : process.env.REACT_APP_PRIMARY_COLOR
                                }
                                fontSize={15}
                              >
                                {customer.email}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <Typography
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#F5F6FC"
                                    : process.env.REACT_APP_PRIMARY_COLOR
                                }
                                fontSize={15}
                              >
                                {customer.profession}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <Typography
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#F5F6FC"
                                    : process.env.REACT_APP_PRIMARY_COLOR
                                }
                                fontSize={15}
                              >
                                {moment(customer.start_session).format("DD/MM/YYYY")}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <Typography
                                color={(theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#F5F6FC"
                                    : process.env.REACT_APP_PRIMARY_COLOR
                                }
                                fontSize={15}
                              >
                                  {moment(customer.end_session).format("DD/MM/YYYY")}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {(customer.options.feedback_state === "à venir" ||
                              (customer.options.feedback_state ===
                                "En attente" &&
                                customer.options.nb_courses === 0)) && (
                              <>
                                <Tooltip title={`à venir`}>
                                  <Avatar
                                    sx={{
                                      backgroundColor: "white",
                                      marginLeft: 5,
                                    }}
                                  >
                                    <BlockIcon
                                      sx={{ color: "grey", fontSize: 30 }}
                                    />
                                  </Avatar>
                                </Tooltip>
                              </>
                            )}
                            {customer.options.feedback_state === "En attente" &&
                              customer.options.nb_courses !== 0 && (
                                <>
                                  {customer.options.nb_courses === 1 ? (
                                    <Tooltip
                                      title={`${customer.options.nb_courses} feedback`}
                                    >
                                      <Avatar
                                        sx={{
                                          backgroundColor: "white",
                                          marginLeft: 5,
                                        }}
                                      >
                                        <HourglassTopRoundedIcon
                                          sx={{
                                            color: "#FF8C00",
                                            fontSize: 30,
                                          }}
                                        />
                                      </Avatar>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={`${customer.options.nb_courses} feedbacks`}
                                    >
                                      <Avatar
                                        sx={{
                                          backgroundColor: "white",
                                          marginLeft: 5,
                                        }}
                                      >
                                        <HourglassTopRoundedIcon
                                          sx={{
                                            color: "#FF8C00",
                                            fontSize: 30,
                                          }}
                                        />
                                      </Avatar>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            {customer.options.feedback_state === "finis" && (
                              <>
                                <Tooltip title={`finis`}>
                                  <Avatar
                                    sx={{
                                      backgroundColor: "white",
                                      marginLeft: 5,
                                    }}
                                  >
                                    <CheckCircleIcon
                                      sx={{ color: "green", fontSize: 30 }}
                                    />
                                  </Avatar>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>)}
                </Table>
              </Scrollbar>)}
              <TablePagination
                component="div"
                count={totalNumberOfUsers}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={pagin}
                labelRowsPerPage="Lignes par page :"
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 100]}
              />
            </Card>
        </Container>
      </Box>
    </>
  );
};
  
export default FeedBacks;
  