import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Fade,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PropertyList } from "../../property-list";
import { PropertyListItem } from "../../property-list-item";
import { useEffect, useState } from "react";
import { KeyboardArrowRight } from "@mui/icons-material";

const CustomPropertyListItem = (props) => {
  const { item } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const align = mdUp ? "horizontal" : "vertical";
  const [showDetails, setShowDetails] = useState(false);
  return (
    <PropertyListItem
      sx={{
        p: 2,
        color: process.env.REACT_APP_SECONDARY_COLOR,
        typography: "subtitle2",
      }}
      key={item.id}
      align={align}
      divider
      setMaxWidth="100%"
      label={"Numéro : " + item.number}
      value={
        <Grid container>
          <Grid item xs={10}>
            <Typography>{item.criteria}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                // color: process.env.REACT_APP_SECONDARY_COLOR,
                ml: -3,
                fontWeight: 600,
              }}
            >
              {"Pondération : " + item.weight}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={{
                mt: -0.8,
                transform: showDetails ? "rotate(90deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
              onClick={() => {
                // handleDeleteButton(index)
                setShowDetails(!showDetails);
              }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
          {showDetails && (
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={5.5}>
                <Typography
                  sx={{
                    color: process.env.REACT_APP_SECONDARY_COLOR,
                    fontWeight: 600,
                  }}
                >
                  Source :
                </Typography>
                <Typography sx={{ whiteSpace: "pre-line" }}>
                  {item.source ?? "Aucune information"}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5.5}>
                <Typography
                  sx={{
                    color: process.env.REACT_APP_SECONDARY_COLOR,
                    fontWeight: 600,
                  }}
                >
                  Guide :
                </Typography>
                <Typography sx={{ whiteSpace: "pre-line" }}>
                  {item.guide ?? "Aucune information"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export const AuditsDetails = (props) => {
  const { result, showSkillCriterias, setShowSkillCriterias } = props;
  // const showCriterias = result.skills?.map(() => false)
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const align = mdUp ? "horizontal" : "vertical";

  const handleShowSkillCriterias = (index) => {
    const newShowSkillCriterias = [...showSkillCriterias];
    newShowSkillCriterias[index] = !newShowSkillCriterias[index];
    setShowSkillCriterias(newShowSkillCriterias);
  };

  return (
    <>
      <Card>
        <CardHeader title="Critères" />
        <Divider />
        <PropertyList>
          {result.criteria
            ? result.criteria.map((item) => (
                <CustomPropertyListItem item={item} />
              ))
            : "Pas de données disponibles"}
        </PropertyList>
      </Card>
      <Card>
        <CardHeader title="Objectifs" />
        <Divider />
        <PropertyList>
          {result.objectives
            ? result.objectives.map((item) => (
                <PropertyListItem
                  key={item.id}
                  align={align}
                  divider
                  label={"Place : " + item.place}
                  value={item.description}
                />
              ))
            : "Pas de données disponibles"}
        </PropertyList>
      </Card>
      <Card>
        <CardHeader title="Compétences" />
        <Divider />
        <PropertyList>
          {result.skills
            ? result.skills?.map((skill, index) => {
                return (
                  <PropertyListItem
                    key={skill.id}
                    align={align}
                    divider
                    setMaxWidth="100%"
                    label={"Numéro : " + (index + 1)}
                    value={
                      <>
                        <Grid container>
                          <Grid item sm={11}>
                            <Typography>{skill.skill}</Typography>
                          </Grid>
                          <Grid item sm={1}>
                            <IconButton
                              onClick={() => handleShowSkillCriterias(index)}
                              style={{
                                transform: showSkillCriterias[index]
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.2s ease",
                              }}
                            >
                              <KeyboardArrowRight />
                            </IconButton>
                          </Grid>
                        </Grid>
                        {showSkillCriterias[index] && (
                          <>
                            {skill.criterias?.length === 0 ||
                            !skill.criterias ? (
                              <Typography sx={{ fontStyle: "italic", mb: 2 }}>
                                Aucun critère attribué
                              </Typography>
                            ) : (
                              <>
                                {skill.criterias?.length === 1 ? (
                                  <Typography
                                    sx={{
                                      fontStyle: "italic",
                                      mb: 2,
                                      textAlign: "center",
                                    }}
                                  >
                                    Critère attribué
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontStyle: "italic",
                                      mb: 2,
                                      textAlign: "center",
                                    }}
                                  >
                                    Critères attribués
                                  </Typography>
                                )}
                                {skill.criterias?.map((criteria) => (
                                  <Grid container>
                                    <Grid
                                      item
                                      sm={12}
                                      sx={{ display: "flex", mb: 2 }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          flex: 0.15,
                                        }}
                                      >
                                        Numéro : {criteria.number}
                                      </Typography>
                                      <Typography sx={{ flex: 0.85, ml: 1 }}>
                                        {criteria.name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                );
              })
            : "Pas de données disponibles"}
        </PropertyList>
      </Card>
    </>
  );
};
