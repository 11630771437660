import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import { useMounted } from "../../../hooks/use-mounted";
import { useAuth } from "../../../hooks/use-auth";
import { auditsApi } from "../../../api/audit-api";
import { FormersApi } from "../../../api/formateur-api";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { frFR } from "@mui/x-data-grid";

export const FormerBasicDetails = (props) => {
  let { formerData, setFormerData } = props;
  const isMounted = useMounted();
  const { user } = useAuth();
  const [modifyText, setModifyText] = useState("");
  const [audits, setAudits] = useState([]);
  const [auditsSessions, setAuditsSessions] = useState({});
  const [selectedSessions, setSelectedSessions] = useState({});
  const [trainerSessions, setTrainerSessions] = useState({});
  const [tempEppIds, setTempEppIds] = useState([]);

  const [modify, setModify] = useState({
    email: null,
    epp_ids: null,
  });

  const handleModify = async (field, data = null) => {
    setModify((modify) => ({
      ...modify,
      [field]: false,
    }));
    let changeSurvey = { ...formerData };
    if (data) {
      changeSurvey[field] = data;
    } else {
      changeSurvey[field] = modifyText;
    }

    setFormerData(changeSurvey);

    if (field === "email") {
      await FormersApi.modifyFormerData(
        user.admin_id,
        formerData.id,
        changeSurvey.email,
        changeSurvey.epp_ids
      );
    }
  };

  const getAudits = useCallback(async () => {
    try {
      const data = await auditsApi.getAllEpp(user.admin_id);
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user.admin_id, isMounted]);

  const getAuditsSessions = useCallback(
    async (epp_ids) => {
      try {
        const epp_ids_str = epp_ids.join(",");
        const data = await auditsApi.getAuditsSessions(
          user.admin_id,
          epp_ids_str
        );
        if (isMounted()) {
          setAuditsSessions((prev) => ({
            ...prev,
            ...data.result,
          }));
        }
      } catch (err) {
        console.error(err);
      }
    },
    [user.admin_id, isMounted]
  );

  const getTrainerSessions = useCallback(async () => {
    try {
      const data = await FormersApi.getTrainerSessions(
        user.admin_id,
        formerData.id
      );
      if (isMounted()) {
        setTrainerSessions(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user.admin_id, isMounted, formerData]);

  useEffect(() => {
    getAudits();
    if (formerData) {
      let initialAudits = [];
      if (Array.isArray(formerData.epp_ids)) {
        initialAudits = formerData.epp_ids;
      } else if (typeof formerData.epp_ids === "string") {
        initialAudits = formerData.epp_ids.split(",");
      } else if (typeof formerData.epp_ids === "number") {
        initialAudits = [formerData.epp_ids.toString()];
      }
      setTempEppIds(initialAudits);
      getAuditsSessions(initialAudits);
      getTrainerSessions();
    }
  }, [getAudits, getAuditsSessions, getTrainerSessions, formerData]);

  useEffect(() => {
    // Préparer selectedSessions en fonction des trainerSessions
    const selected = {};
    for (const auditId in auditsSessions) {
      if (trainerSessions && trainerSessions.length > 0) {
        selected[auditId] = auditsSessions[auditId].filter((session) =>
          trainerSessions.some(
            (trainerSession) =>
              trainerSession.syna_sessions_ids == session.id &&
              trainerSession.syna_formation_id == session.formations_id &&
              !trainerSession.is_deleted
          )
        );
      }
    }
    setSelectedSessions(selected);
  }, [auditsSessions, trainerSessions]);

  const handleTempEppIdsChange = async (newEppIds) => {
    const addedEppIds = newEppIds.filter((id) => !tempEppIds.includes(id));
    const removedEppIds = tempEppIds.filter((id) => !newEppIds.includes(id));

    setTempEppIds(newEppIds);

    if (addedEppIds.length > 0) {
      await getAuditsSessions(addedEppIds);
    }

    for (let audit of removedEppIds) {
      await FormersApi.deleteAllSessionsOfTrainer(
        user.admin_id,
        formerData.id,
        audit
      );
    }
  };

  const handleTempSelectedSessionsChange = async (epp_id, newValue) => {
    const previousSessions = selectedSessions[epp_id] || [];
    const addedSessions = newValue.filter(
      (session) =>
        !previousSessions.some((prevSession) => prevSession.id === session.id)
    );
    const removedSessions = previousSessions.filter(
      (prevSession) =>
        !newValue.some((session) => session.id === prevSession.id)
    );

    for (let session of addedSessions) {
      const session_id = session.id;
      const formation_id = session.formations_id;
      const session_number = session.number_session;

      await FormersApi.setTrainerSessions(
        user.admin_id,
        epp_id,
        formerData.id,
        session_id,
        formation_id,
        session_number
      );
    }

    for (let session of removedSessions) {
      const session_id = session.id;
      await FormersApi.deleteSessionOfTrainer(
        user.admin_id,
        epp_id,
        session_id
      );
    }

    setSelectedSessions((prev) => ({
      ...prev,
      [epp_id]: newValue,
    }));
  };

  return (
    <Card>
      <CardHeader title="Informations" />
      <Divider />
      <List disablePadding>
        <ListItem
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 0,
            }}
            disableTypography
            primary={
              <Typography sx={{ minWidth: 180 }} variant="subtitle2">
                Email
              </Typography>
            }
            secondary={
              <Box
                sx={{
                  flex: 1,
                  mt: -0.5,
                }}
              >
                {modify.email ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={modifyText}
                    onChange={(event) => setModifyText(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            sx={{ mr: 2 }}
                            onClick={() => handleModify("email", modifyText)}
                          >
                            Valider
                          </Button>
                          <Button onClick={() => handleModify("email")}>
                            Annuler
                          </Button>
                        </>
                      ),
                    }}
                  />
                ) : (
                  <Typography
                    onClick={() => {
                      setModifyText(formerData.email);
                      setModify((modify) => ({
                        ...modify,
                        email: true,
                      }));
                    }}
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                    color="textSecondary"
                    variant="body2"
                  >
                    {formerData.email}
                  </Typography>
                )}
              </Box>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 0,
            }}
            disableTypography
            primary={
              <Typography sx={{ minWidth: 180 }} variant="subtitle2">
                EPP associés
              </Typography>
            }
            secondary={
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000,
                }}
              >
                <Autocomplete
                  sx={{ minWidth: 300 }}
                  multiple
                  id="audits-autocomplete"
                  options={audits}
                  getOptionLabel={(audit) => audit.name}
                  value={
                    audits
                      ? audits.filter((audit) => tempEppIds.includes(audit.id))
                      : []
                  }
                  onChange={(event, newValue) => {
                    handleTempEppIdsChange(newValue.map((audit) => audit.id));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Sélectionnez les epp"
                    />
                  )}
                />
              </Box>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "row",
              my: 0,
            }}
            disableTypography
            primary={
              <Typography sx={{ minWidth: 180 }} variant="subtitle2">
                Sessions associés
              </Typography>
            }
            secondary={
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000,
                }}
              >
                <List>
                  {tempEppIds.length > 0 &&
                    tempEppIds.map((epp_id) => {
                      const audit = audits.find((a) => a.id === epp_id);
                      return audit ? (
                        <div key={epp_id}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {`Sessions : ${audit.name}`}
                                </Typography>
                              }
                              secondary={
                                auditsSessions &&
                                auditsSessions[epp_id] &&
                                auditsSessions[epp_id].length > 0 ? (
                                  <Autocomplete
                                    multiple
                                    id={`sessions-autocomplete-${epp_id}`}
                                    options={auditsSessions[epp_id]}
                                    getOptionLabel={(session) =>
                                      session.label
                                        ? session.label
                                        : `Session N°${
                                            session.number_session
                                          } (${format(
                                            new Date(session.start_date),
                                            "dd MMMM yyyy",
                                            { locale: fr }
                                          )} - ${format(
                                            new Date(session.end_date),
                                            "dd MMMM yyyy",
                                            { locale: fr }
                                          )})`
                                    }
                                    value={selectedSessions[epp_id] || []}
                                    onChange={(event, newValue) => {
                                      handleTempSelectedSessionsChange(
                                        epp_id,
                                        newValue
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label={`Sélectionnez les sessions pour ${audit.name}`}
                                      />
                                    )}
                                  />
                                ) : (
                                  <ListItem>
                                    <ListItemText primary="Pas de sessions disponibles pour le moment" />
                                  </ListItem>
                                )
                              }
                            />
                          </ListItem>
                          <Divider />
                        </div>
                      ) : null;
                    })}
                </List>
              </Box>
            }
          />
        </ListItem>
      </List>
      <Divider />
    </Card>
  );
};
