class ScoresApi {
    
    async getScoresMeans(admin_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/rag_system/feedback/get-feedback-score-means`;
      const values = {
        admin_id: admin_id,
      };
      const accessToken = globalThis.localStorage.getItem("accessToken");
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const json = await resp.json();
      return Promise.resolve(json);
    }
    
    async getMinMaxFeedbackScore(admin_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/get-min_max-rating-feedback-scores`;
      const values = {
        admin_id: admin_id,
      };
      const accessToken = globalThis.localStorage.getItem("accessToken");
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const json = await resp.json();
      return Promise.resolve(json);
    }
    
    
}
  
export const scoresApi = new ScoresApi();
  