import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
  Divider,
  TextField
} from '@mui/material';
import { Scrollbar } from '../../scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formationsApi } from '../../../api/formation-api';
import { useAuth } from '../../../hooks/use-auth';
import { useNavigate } from "react-router-dom";

export const FormationsList = (props) => {
  const { formations } = props;
  const { user } = useAuth();

  const [filteredFormations, setFilteredFormations] = useState([])
  const [searchName, setSearchName] = useState([])
  const [createBool, setCreateBool] = useState(false)
  const [createFormationName, setCreateFormationName] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredFormations(formations)
  }, [formations])

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setFilteredFormations(formations.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  const handleCreateFormation = async event => {
    const id = await formationsApi.createFormation(user.admin_id, createFormationName);
    navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/formations/${id.id}`)
  }

  return (
    <>
      <Box
        sx={{
          m: 1,
          width: '95%',
          display: 'flex',
          justifyContent: "flex-end",
        }}
      >
        { (createBool === false ?
          <Button
            onClick={() => {
              setCreateBool(true)
            }}
            variant='contained'
          >
            Créer une formation
          </Button>:
        
          <Box>
            <TextField
              onChange={(event) => {
                setCreateFormationName(event.target.value)
              }}
              sx={{
                backgroundColor:'white',
              }}
              label="Entrez le nom de la formation"
            />
            <Button
              sx={{
                m:1
              }}
              variant='contained'
              onClick={() => {
                setCreateBool(false)
              }}
            >
              Annuler
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                handleCreateFormation()
              }}
            >
              Créer
            </Button>
          </Box>
        )}

      </Box>
      <Card {...props}>
        <Scrollbar>
          <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2.5
              }}
          >
            <SearchIcon fontSize="small" />
            <Box
              sx={{
                flexGrow: 1,
                ml: 3
              }}
            >
              <Input
                color='secondary'
                disableUnderline
                fullWidth
                value={searchName}
                onChange={handleChangeSearch}
                placeholder="Chercher une formation"
              />
            </Box>
          </Box>
          <Divider />
          <Table sx={{ minWidth: 700 }}>
            <TableBody>
              {formations ? filteredFormations.map((formation, index) => (
                <TableRow
                  hover
                  key={index}
                >
                  <TableCell>
                    <Box sx={{ ml: 3 }}>
                      <IconButton
                        component = {RouterLink}
                        to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/formations/${formation.id}`}
                      >
                        <Typography variant="h6"
                          color='primary'
                        >
                          {formation.name}
                        </Typography>
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )) : NULL}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  )
};
