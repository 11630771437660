class FormateurApi {
  async getAllFormers(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateurs/getAll/`;
    const values = {
      admin_id: admin_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getFormerById(admin_id, former_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateur/get/`;
    const values = {
      admin_id: admin_id,
      former_id: former_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyFormerData(admin_id, former_id, email, epp_ids) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateur/modify/`;
    const values = {
      admin_id: admin_id,
      former_id: former_id,
      epp_ids: epp_ids,
      email: email,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  
  async setTrainerSessions(admin_id, epp_id,trainer_id,sessions_ids,formation_id,session_number) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateur/setsessions/`;
    const values = {
      admin_id: admin_id,
      epp_id: epp_id,
      trainer_id : trainer_id,
      sessions_ids: sessions_ids,
      formation_id: formation_id,
      session_number: session_number,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getTrainerSessions(admin_id, trainer_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateur/sessions/`;
    const values = {
      admin_id: admin_id,
      trainer_id : trainer_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async deleteAllSessionsOfTrainer(admin_id,trainer_id, epp_id) {
    try {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateur/deleteSessions/`;
      const values = {
        admin_id: admin_id,
        trainer_id: trainer_id,
        epp_id:epp_id
      };
      const accessToken = globalThis.localStorage.getItem("accessToken");
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const json = await resp.json();
      return Promise.resolve(json);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
  
  async deleteSessionOfTrainer(admin_id,epp_id, session_id) {
    try {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/formateur/deleteOneSession/`;
      const values = {
        admin_id: admin_id,
        epp_id: epp_id,
        session_id: session_id,
      };
      const accessToken = globalThis.localStorage.getItem("accessToken");
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const json = await resp.json();
      return Promise.resolve(json);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
  
}

export const FormersApi = new FormateurApi();
