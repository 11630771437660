import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useAuth } from "../../hooks/use-auth";
import { useLocation, useNavigate } from "react-router-dom";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ListIcon from "@mui/icons-material/List";
import TroubleshootOutlinedIcon from "@mui/icons-material/TroubleshootOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MenuIcon from "@mui/icons-material/Menu";

export const DashboardSidebar = (props) => {
  const { onClose, open, anchorEl,openSide, setOpenSide, ...other } = props;
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState("Analyses");
  const [selectedSubtitles, setSelectedSubtitles] = useState({
    Analyses: "Tableau de bord",
  });

  const handleOpen = () => setOpenSide(true);
  const handleClose = () => setOpenSide(false);

  useEffect(() => {
    if (user.id_service === 0) {
      setSections([
        {
          title: "Analyses",
          icon: <TroubleshootOutlinedIcon fontSize="small" />,
          items: [
            { title: "Tableau de bord", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard` },
            { title: "Agenda", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/agenda` },
            { title: "Scores", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/scores` },
          ],
        },
        {
          title: "Commercial",
          icon: <GroupOutlinedIcon fontSize="small" />,
          items: [
            { title: "Apprenants", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers` },
            { title: "Créer un apprenant", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/create` },
          ],
        },
        {
          title: "Évaluations",
          icon: <AssessmentOutlinedIcon fontSize="small" />,
          items: [
            { title: "Formations", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/formations` },
            { title: "Audits", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/audits` },
            { title: "Dossiers patients", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/patients_folder` },
          ],
        },
        {
          title: "Qualité",
          icon: <VerifiedOutlinedIcon fontSize="small" />,
          items: [{ title: "Satisfaction", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/survey` }],
        },
        {
          title: "Sessions",
          icon: <Groups2OutlinedIcon fontSize="small" />,
          items: [
            { title: "Liste des sessions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session` },
            { title: "Liste des formateurs", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/formateur` },
          ],
        },
        {
          title: "Autres",
          icon: <MoreHorizOutlinedIcon fontSize="small" />,
          items: [
            { title: "Créer un admin", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/createAdmin` },
            { title: "Activités", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/activities` },
            { title: "Erreurs", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/errors` },
            { title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` },
            { title: "Facturation", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/facturation` },
            { title: "Paramètres", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/params` },
          ],
        },
      ]);
    } else if (user.id_service === 1) {
      setSections([
        {
          title: "Analyses",
          icon: <HomeIcon fontSize="small" />,
          items: [
            { title: "Tableau de bord", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard` },
            { title: "Agenda", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/agenda` },
          ],
        },
        {
          title: "Évaluations",
          icon: <VerifiedOutlinedIcon fontSize="small" />,
          items: [
            { title: "Audits", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/audits` },
            { title: "Apprenants", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers` },
          ],
        },
        {
          title: "Sessions",
          icon: <ListIcon fontSize="small" />,
          items: [{ title: "Liste des sessions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session` }],
        },
        {
          title: "Autres",
          icon: <ForumIcon fontSize="small" />,
          items: [{ title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` }],
        },
      ]);
    }
  }, [user]);

  const handleToggleSection = (title) => {
    setExpandedSections((prev) => (prev === title ? null : title));
  };

  const handleSelectSubtitle = (sectionTitle, subtitle, path) => {
    if (expandedSections != sectionTitle){
      handleToggleSection(sectionTitle);
    }
    
      setSelectedSubtitles((prev) => ({
      ...prev,
      [sectionTitle]: prev[sectionTitle] === subtitle ? subtitle : subtitle,
      
    }));

    if (path) {
      navigate(path);
    }
    
    
    
  };

  const content = (
    <Box
      {...other}
      anchorEl={anchorEl}
      sx={{
        display: "flex",
        flexDirection: "column",
        // height: "100%",
        backgroundColor: "#8282CA",
        // paddingTop: 10,
      }}
    >
      <Box
        component="img"
        sx={{
          mt: 2,
          ml: 4,
          width: "200px",
          height: "80px",
        }}
        alt="Kalanke"
        src={process.env.REACT_APP_LOGO_NAVBAR}
      />
      <Box sx={{ flexGrow: 1, p: 2, pt: 5 }}>
        {sections.map((section) => (
          <Box key={section.title} mt={2} mb={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color:
                  expandedSections === section.title ? "#7676C5" : "#FFFFFF",
                backgroundColor:
                  expandedSections === section.title
                    ? "#DDDDF1"
                    : "transparent",
                borderRadius: 1,
                padding: expandedSections === section.title ? 1 : "0",
              }}
              onClick={() => handleToggleSection(section.title)}
            >
              {section.icon}
              <Typography
                variant="h6"
                sx={{ ml: 1, fontWeight: "bold", fontSize: 15 }}
              >
                {section.title}
              </Typography>
            </Box>
            {/* {expandedSections === section.title && ( */}
              <Box sx={{ pl: 7 }}>
                {section.items.map((item) => (
                  <Typography
                    key={item.title}
                    variant="body1"
                    sx={{
                      color: "#FFFFFF",
                      mt: 2,
                      fontSize: 14,
                      fontWeight: "bold",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      ml:
                     ( expandedSections === section.title &&  selectedSubtitles[section.title] === item.title)
                          ? -1
                          : 1,
                    }}
                    onClick={() =>
                      handleSelectSubtitle(section.title, item.title, item.path)
                    }
                  >
                    { (expandedSections === section.title &&  selectedSubtitles[section.title] === item.title) && (
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50%",
                          marginRight: 1,
                          fontWeight: "bold",
                        }}
                      />
                    )}
                    {item.title}
                  </Typography>
                ))}
              </Box>
            {/*  )} */}
          </Box>
        ))}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 280,
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open sidebar"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={true}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 280,
          },
        }}
      >
        {content}
      </Drawer>
    </div>
  );
};

DashboardSidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
};

export default DashboardSidebar;









// import { useMemo, useState } from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import {
//   Box,
//   Divider,
//   Drawer,
//   Typography,
//   Avatar,
//   useMediaQuery,
// } from "@mui/material";
// import { DashboardSidebarSection } from "./dashboard-sidebar-section";
// import { useAuth } from "../../hooks/use-auth";
// import { useLocation } from "react-router-dom";
// import { IconButton } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { useEffect } from "react";
// import ArticleIcon from "@mui/icons-material/Article";
// import { AdfScanner, FlakyOutlined } from "@mui/icons-material";
// import ForumIcon from "@mui/icons-material/Forum";
// import ListIcon from "@mui/icons-material/List";
// import HomeIcon from "@mui/icons-material/Home";
// import DateRangeIcon from "@mui/icons-material/DateRange";
// import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
// import GroupIcon from "@mui/icons-material/Group";
// import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
// import ControlPointIcon from "@mui/icons-material/ControlPoint";
// import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import CogIcon from "../../icons/Cog";
// import FeedbackIcon from "@mui/icons-material/Feedback";
// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// import ChatIcon from "@mui/icons-material/Chat";
// import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
// import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
// import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

// export const DashboardSidebar = (props) => {
//   const { onClose, open, anchorEl, ...other } = props;
//   const { user } = useAuth();
//   const location = useLocation();
//   const { t } = useTranslation();
//   const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
//     noSsr: true,
//   });
//   const [openSide, setOpenSide] = useState(false);
//   const [sections, setSections] = useState([]);
//   const handleOpen = () => setOpenSide(true);
//   const handleClose = () => setOpenSide(false);

//   // const sections = (useMemo(() => getSections(t), [t]));

//   useEffect(() => {
//     if (user.id_service === 0) {
//       setSections([
//         {
//           title: "Analyses",
//           items: [
//             {
//               title: "Tableau de bord",
//               path: "/dashboard",
//               icon: <HomeIcon fontSize="small" />,
//             },
//             {
//               title: "Agenda",
//               path: "/:tenant/dashboard/agenda",
//               icon: <DateRangeIcon fontSize="small" />,
//             },
//             {
//               title: "Scores",
//               path: "/:tenant/dashboard/scores",
//               icon: <StarOutlineOutlinedIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Commercial",
//           items: [
//             {
//               title: "Apprenants",
//               path: "/:tenant/dashboard/customers",
//               icon: <GroupIcon fontSize="small" />,
//             },
//             {
//               title: "Créer un apprenant",
//               path: "/:tenant/dashboard/create",
//               icon: <ControlPointIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Évaluations",
//           items: [
//             {
//               title: "Formations",
//               path: "/:tenant/dashboard/formations",
//               icon: <ArticleIcon fontSize="small" />,
//             },
//             {
//               title: "Audits",
//               path: "/:tenant/dashboard/audits",
//               icon: <VerifiedOutlinedIcon fontSize="small" />,
//             },
//             // {
//             //   title: ('TCS'),
//             //   path: '/dashboard/tcs',
//             //   icon: <FlakyOutlined fontSize="small" />,
//             // },
//             {
//               title: "Dossiers patients",
//               path: "/:tenant/dashboard/patients_folder",
//               icon: <TopicOutlinedIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Qualité",
//           items: [
//             {
//               title: "Satisfaction",
//               path: "/:tenant/dashboard/survey",
//               icon: <SentimentSatisfiedAltIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Sessions",
//           items: [
//             {
//               title: "Liste des sessions",
//               path: "/:tenant/dashboard/session",
//               icon: <ListIcon fontSize="small" />,
//             },
//             {
//               title: "Liste des formateurs",
//               path: "/:tenant/dashboard/formateur",
//               icon: <PeopleOutlineIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Autres",
//           items: [
//             {
//               title: "Créer un admin",
//               path: "/:tenant/dashboard/createAdmin",
//               icon: <SupervisorAccountIcon fontSize="small" />,
//             },
//             {
//               title: "Activités",
//               path: "/:tenant/dashboard/activities",
//               icon: <TrendingUpIcon fontSize="small" />,
//             },
//             {
//               title: "Erreurs",
//               path: "/:tenant/dashboard/errors",
//               icon: <WarningRoundedIcon fontSize="small" />,
//             },
//             {
//               title: "Forum",
//               path: "/:tenant/dashboard/forum",
//               icon: <ForumIcon fontSize="small" />,
//             },
//             {
//               title: "Facturation",
//               path: "/:tenant/dashboard/facturation",
//               icon: <AdfScanner fontSize="small" />,
//             },
//             {
//               title: "Paramètres",
//               path: "/:tenant/dashboard/params",
//               icon: <CogIcon fontSize="small" />,
//             },
//           ],
//         },
//       ]);
//     } else if (user.id_service === 1) {
//       setSections([
//         {
//           title: "Analyses",
//           items: [
//             {
//               title: "Tableau de bord",
//               path: "/dashboard",
//               icon: <HomeIcon fontSize="small" />,
//             },
//             {
//               title: "Agenda",
//               path: "/:tenant/dashboard/agenda",
//               icon: <DateRangeIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Évaluations",
//           items: [
//             {
//               title: "Audits",
//               path: "/:tenant/dashboard/audits",
//               icon: <VerifiedOutlinedIcon fontSize="small" />,
//             },
//             // {
//             //   title: ('TCS'),
//             //   path: '/dashboard/tcs',
//             //   icon: <FlakyOutlined fontSize="small" />,
//             // },
//             {
//               title: "Apprenants",
//               path: "/:tenant/dashboard/customers",
//               icon: <GroupIcon fontSize="small" />,
//             },
//             // {
//             //   title: ('Dossiers patients'),
//             //   path: '/dashboard/patients_folder',
//             //   icon: <TopicOutlinedIcon fontSize="small" />,
//             // },
//           ],
//         },
//         {
//           title: "Sessions",
//           items: [
//             {
//               title: "Liste des sessions",
//               path: "/:tenant/dashboard/session",
//               icon: <ListIcon fontSize="small" />,
//             },
//           ],
//         },
//         {
//           title: "Autres",
//           items: [
//             // {
//             //   title: ('Forum'),
//             //   path: '/dashboard/forum',
//             //   icon: <ForumIcon fontSize="small" />
//             // },
//             {
//               title: "Forum",
//               path: "/:tenant/dashboard/forum",
//               icon: <ForumIcon fontSize="small" />,
//             },
//             // {
//             //   title: ('Paramètres'),
//             //   path: '/dashboard/params',
//             //   icon: <CogIcon fontSize="small" />,
//             // },
//           ],
//         },
//       ]);
//     }
//   }, [user]);

//   const content = (
//     <Box
//       {...other}
//       anchorEl={anchorEl}
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         height: "100%",
//         // paddingTop: 9,
//         backgroundColor: (theme) =>
//           theme.palette.mode === "dark"
//             ? process.env.REACT_APP_PRIMARY_COLOR
//             : "#FFFFFF",
//       }}
//     >
//       <Box
//         component="img"
//         sx={{
//           mt: 0.7,
//           ml: 2,
//           width: "200px",
//           height: "50px",
//         }}
//         alt="Kalanke"
//         src={process.env.REACT_APP_LOGO_NAVBAR}
//       />
//       <Box sx={{ p: 2 }}>
//         <Box
//           sx={{
//             alignItems: "center",
//             borderRadius: 5,
//             display: "flex",
//             overflow: "hidden",
//             p: 2,
//             background: (theme) =>
//               theme.palette.mode === "dark"
//                 ? "#1E2022"
//                 : process.env.REACT_APP_PRIMARY_COLOR,
//           }}
//         >
//           <Avatar
//             src={user.avatar}
//             sx={{
//               height: 50,
//               width: 50,
//             }}
//           />
//           <Box sx={{ ml: 2 }}>
//             <Typography variant="h6" sx={{ color: "#FFFFFF" }}>
//               {user.firstname} {user.lastname}
//             </Typography>
//             {user.id_service === 0 ? (
//               <Typography
//                 color={process.env.REACT_APP_SECONDARY_COLOR}
//                 variant="body2"
//               >
//                 Administrateur
//               </Typography>
//             ) : (
//               <Typography
//                 sx={{
//                   color: (theme) =>
//                     theme.palette.mode === "dark"
//                       ? "white"
//                       : process.env.REACT_APP_SECONDARY_COLOR,
//                 }}
//                 variant="body2"
//               >
//                 Expert
//               </Typography>
//             )}
//           </Box>
//         </Box>
//       </Box>
//       <Divider />
//       <Box sx={{ flexGrow: 1 }}>
//         {sections.map((section) => (
//           <DashboardSidebarSection
//             key={section.title}
//             path={location.pathname}
//             title={section.title}
//             sx={{
//               mt: 1,
//               "& + &": {
//                 mt: 0,
//               },
//             }}
//             {...section}
//           />
//         ))}
//       </Box>
//     </Box>
//   );

//   if (lgUp) {
//     return (
//       <Drawer
//         anchor="left"
//         open
//         PaperProps={{
//           sx: {
//             backgroundColor: "white",
//             borderRightColor: "divider",
//             borderRightStyle: "solid",
//             borderRightWidth: (theme) =>
//               theme.palette.mode === "dark" ? 1 : 0,
//             color: process.env.REACT_APP_PRIMARY_COLOR,
//             width: 280,
//           },
//         }}
//         variant="persistent"
//       >
//         {content}
//       </Drawer>
//     );
//   }

//   return (
//     <div style={{ display: "flex" }}>
//       <IconButton
//         color="inherit"
//         aria-label="open sidebar"
//         onClick={handleOpen}
//       >
//         <MenuIcon />
//       </IconButton>
//       <Drawer
//         anchor="left"
//         open={openSide}
//         onClose={handleClose}
//         PaperProps={{
//           sx: {
//             backgroundColor: "white",
//             borderRightColor: "divider",
//             borderRightStyle: "solid",
//             borderRightWidth: (theme) =>
//               theme.palette.mode === "dark" ? 1 : 0,
//             color: process.env.REACT_APP_PRIMARY_COLOR,
//             width: 280,
//           },
//         }}
//       >
//         {content}
//       </Drawer>
//     </div>
//   );
// };

// DashboardSidebar.propTypes = {
//   onClose: PropTypes.func,
//   open: PropTypes.bool,
// };