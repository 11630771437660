import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Drawer, Typography, IconButton } from "@material-ui/core";
import CustomerDetails from "../../../pages/dashboard/customers/[customerId]";
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenWithIcon from '@mui/icons-material/OpenWith';

const CustumerDetailsDraggableDrawer = (props) => {
  const { customer_id, customer_name } = props;
  const [openDraggabledrawer, setOpenDraggableDrawer] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(50); // Width in percentage (vw)
  const resizableRef = useRef(null);

  const handleOpenDrawer = () => {
    setOpenDraggableDrawer(!openDraggabledrawer);
  };

  const handleCloseDrawer = () => {
    setOpenDraggableDrawer(false);
  };

  // Handle resizing when the user drags the circle
  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = ((window.innerWidth - e.clientX) / window.innerWidth) * 100; 
    // Adjust drawer width based on cursor position, reversed logic
    if (newWidth > 30 && newWidth < 90) {
      setDrawerWidth(newWidth); // Limiting resizing between 20% and 80%
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      <a onClick={handleOpenDrawer} style={{ textDecoration: "none", width:'100%' , alignItems:'center', alignContent:"center", height:"100%"}}>
        <div style={{ display: "flex",  alignItems:'center', alignContent:"center", justifyContent:'center', height:"100%" }}>
          {/* <Typography
            color={(theme) =>
              theme.palette.mode === "dark"
                ? process.env.REACT_APP_SECONDARY_COLOR
                : process.env.REACT_APP_PRIMARY_COLOR
            }
            fontWeight="Bold"
            fontSize={14}
          >
            {customer_name}
          </Typography> */}
          <IconButton
            style={{
              color: process.env.REACT_APP_PRIMARY_COLOR,
              padding: 0,
              marginLeft: "10px"
            }}
            aria-label="view details"
          >
            <VisibilityIcon />
          </IconButton>
        </div>
      </a>

      <Drawer
  open={openDraggabledrawer}
  anchor={"right"}
  onClose={handleCloseDrawer}
  PaperProps={{
    style: { width: `${drawerWidth}vw`, minWidth: "20vw", maxWidth: "80vw", overflow: "visible" } // Add overflow: visible
  }}
>
  <div style={{ display: "flex", height: "100%", marginLeft: '-15px' }}>
    {/* Resize handle on the left */}
    <div
      ref={resizableRef}
      onMouseDown={handleMouseDown}
      style={{
        width: "30px",
        height: "30px",
        marginTop: '20vh',
        cursor: "move",
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 1000 // Ensure the handle is on top
      }}
    >
      {/* Circle handle */}
<div
  style={{
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: '1px solid',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  }}
>
  {/* Adding the move icon inside */}
  <div
 
 style={{display: "flex", alignContent: "center",alignItems: "center",justifyContent: "center"}}
  >
   <OpenWithIcon/>
  </div>
</div>

    </div>

    {/* Main drawer content */}
    <div style={{ flexGrow: 1, overflow:'auto' }}>
      <CustomerDetails customer_id={customer_id} />
    </div>
  </div>
</Drawer>

    </>
  );
};

export default CustumerDetailsDraggableDrawer;

CustumerDetailsDraggableDrawer.propTypes = {
  customer_id: PropTypes.number.isRequired,
  customer_name: PropTypes.string.isRequired,
};
