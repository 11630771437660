import { useCallback, useEffect, useState } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Fab,
  InputLabel,
  Link,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  CardHeader,
} from "@mui/material";
import { useAuth } from "../../../hooks/use-auth";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { criteriasApi } from "../../../api/criteria-api";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CriteriaCard = (props) => {
  const {
    index,
    include,
    skill_id,
    skills,
    criteriaId,
    criteria,
    initialWeight,
    initialGuide,
    initialSource,
    handleModifyInclude,
    handleModifyCriteriaSkill,
    handleDeleteButton,
    handleDoubleClick,
    provided,
  } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [weight, setWeight] = useState(initialWeight);
  const [source, setSource] = useState(initialSource);
  const [guide, setGuide] = useState(initialGuide);
  const { user } = useAuth();

  const handleGuide = async (value) => {
    setGuide(value);
    await criteriasApi.modifyGuide(user.admin_id, criteriaId, value);
  };

  const handleSource = async (value) => {
    setSource(value);
    await criteriasApi.modifySource(user.admin_id, criteriaId, value);
  };

  const handleWeight = async (value) => {
    setWeight(value);
    await criteriasApi.modifyWeight(user.admin_id, criteriaId, value);
  };

  const choiceOptions = [
    { label: "Non inclus", value: 1 },
    { label: "Neutre", value: 2 },
    { label: "Inclus", value: 3 },
  ];

  return (
    <Grid
      sx={{
        width: "95%",
      }}
    >
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          m: 1,
          p: 1,
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
          }}
        >
          <Grid
            item
            sm={7}
            sx={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              px: 2,
            }}
            onDoubleClick={() => {
              handleDoubleClick(index, true);
            }}
          >
            <Typography>
              {"Numéro : " + (index + 1) + " " + criteria}
            </Typography>
          </Grid>
          <Grid container sm={4} spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <TextField
                label="Pondération"
                textAlign={"center"}
                type="number"
                value={weight}
                onChange={(event) => handleWeight(event.target.value)}
              />
            </Grid>
            <Grid
              item
              sm={8}
              // style={{ marginLeft: '10px' }}
            >
              <TextField
                label="Inclusion"
                name="choice"
                onChange={(event) => {
                  handleModifyInclude(index, event.target.value);
                }}
                select
                SelectProps={{ native: true }}
                value={include}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                {choiceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              sm={12}
              sx={{ mt: 1 }}
              // style={{ marginLeft: '10px' }}
            >
              <TextField
                label="Compétence"
                name="choice"
                onChange={(event) => {
                  handleModifyCriteriaSkill(index, event.target.value);
                }}
                select
                SelectProps={{ native: true }}
                defaultValue={skill_id}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <option key="default" value={null}>
                  Aucune
                </option>
                {skills.map((skill) => (
                  <option key={skill.id} value={skill.id}>
                    {skill.skill}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box textAlign={"right"}>
              <Button
                sx={{
                  textAlign: "right",
                }}
                onClick={() => {
                  handleDeleteButton(index);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </Box>
            <Box textAlign={"right"}>
              <Button
                sx={{
                  textAlign: "right",
                }}
                onClick={() => {
                  // handleDeleteButton(index)
                  setShowDetails(!showDetails);
                }}
              >
                {showDetails ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {showDetails && (
          <Grid container>
            <Grid item xs={5.8} sx={{ mt: 1 }}>
              <TextField
                label="Source"
                name="source"
                multiline
                rows={5}
                variant="outlined"
                value={source}
                onChange={(e) => handleSource(e.target.value)}
                // InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid xs={0.4} />
            <Grid item xs={5.8} sx={{ mt: 1 }}>
              <TextField
                label="Guide"
                name="guide"
                multiline
                rows={5}
                variant="outlined"
                value={guide}
                onChange={(e) => handleGuide(e.target.value)}
                // InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};

export default CriteriaCard;
