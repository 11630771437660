class AuditsApi {
  async createAudit(admin_id, name, type) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audit/create/`;
    const values = {
      admin_id: admin_id,
      audit_name: name,
      type: type,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async meanTime(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audit/meanTime/`;
    const values = {
      admin_id: admin_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async modifyAudit(admin_id, audit_info) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audit/modify/`;
    const values = {
      admin_id: admin_id,
      audit_info: audit_info,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getAllEpp(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audit/getAllEpp/`;
    const values = {
      admin_id: admin_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getAllAudits(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audit/getAllAudits/`;
    const values = {
      admin_id: admin_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async getAuditsSessions(admin_id, epp_ids) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/epp/getsessions/`;
    const values = {
      admin_id: admin_id,
      epp_ids: epp_ids,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async createIntroductionPart(
    admin_id,
    epp_id,
    chapter_number,
    chapter_title,
    content
  ) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audits/introduction/create/`;
    const values = {
      admin_id: admin_id,
      epp_id: epp_id,
      chapter_number: chapter_number,
      chapter_title: chapter_title,
      content: content,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async deleteIntroductionPart(admin_id, chapter_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audits/introduction/delete/`;
    const values = {
      admin_id: admin_id,
      chapter_id: chapter_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async editIntroductionPart(admin_id, chapter) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audits/introduction/edit/`;
    const values = {
      admin_id: admin_id,
      chapter_id: chapter.id,
      chapter_number: chapter.number,
      chapter_title: chapter.title,
      content: chapter.content,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getIntroductionPartsByAudit(admin_id, epp_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audits/introduction/get-all-parts/`;
    const values = {
      admin_id: admin_id,
      epp_id: epp_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async getIntroductionPartByChapterNumber(admin_id, epp_id, chapter_number) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audits/introduction/get-part/`;
    const values = {
      admin_id: admin_id,
      epp_id: epp_id,
      chapter_number: chapter_number,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
}

export const auditsApi = new AuditsApi();
