class SkillsApi {
    async createSkill(admin_id, skill_name, skill_number, audits_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/skill/create/`
        const values = {
            "admin_id": admin_id,
            "skill": skill_name,
            "number": skill_number,
            "epp_id": audits_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async deleteSkill(admin_id, skill_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/skill/delete/`
        const values = {
            "admin_id": admin_id,
            "id": skill_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async modifySkill(admin_id, data) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/skill/modify/`
        const values = {} 

        values["admin_id"] = admin_id
        values["id"] = data.skill_id
        if ("number" in data) {
            values["number"] = data.number
        }
        if ("skill" in data) {
            values["skill"] = data.skill
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const skillsApi = new SkillsApi();