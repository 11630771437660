import React from 'react';
import { Navigate ,useParams} from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

const AdminProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const { tenant } = useParams();
  if (tenant!=globalThis.localStorage.getItem('tenant') || !user) {
    // Rediriger vers la page de login si l'utilisateur n'est pas authentifié
    return <Navigate to="/" />;
  }

  if (user.id_service !== 0 && user.access_level !== 0) {
    return <Navigate to="/not-available" />;
  }

  // Rendre le composant enfant si l'utilisateur est un admin
  return children;
};

export default AdminProtectedRoute;
