import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,Grid,  CircularProgress, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo_Kalanke from './../../../images/Logo-Kalanke.png';
import { useParams, useNavigate } from 'react-router-dom';

const CreateTenantPage = () => {

  const navigate = useNavigate(); // For navigation if needed


  const [tenantName, setTenantName] = useState('');
  const [adminFirstName, setAdminFirstName] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('error');
  const [loading, setLoading] = useState(false); // Loading state to manage tenant check
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const createNewTenant=async(data)=> {
  
    const url = `${process.env.REACT_APP_BACK}/create_tenant_and_domain/`
    const values = {
      "name": data.name.toLowerCase(),
      "adminFirstName": data.adminFirstName,
      "adminLastName": data.adminLastName,
      "adminEmail": data.adminEmail,
      "adminPassword": data.adminPassword
    };
    const resp = await fetch(url, {
      
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        
}}
);
    const json = await resp.json();

    if (json.msg=="success" ){
      SetupAdminSpace(data)
    }
    // setLoading(false);
    return Promise.resolve(json)
  
}

const SetupAdminSpace=async(data)=> {
  
    const url = `${process.env.REACT_APP_BACK}/setup_admin_space/`
    const values = {
      "name": data.name.toLowerCase(),
      "adminFirstName": data.adminFirstName,
      "adminLastName": data.adminLastName,
      "adminEmail": data.adminEmail,
      "adminPassword": data.adminPassword
    };
    const resp = await fetch(url, {
      
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        
}}
);
    const json = await resp.json();

    if (json.msg=="success" ){

      setLoading(false);
       navigate(`/${data.name.toLowerCase()}/`);
    }
    else{
      setToastMessage("Une erreur de serveur s'est produite, veuillez réessayer plus tard");
      setToastSeverity('error');

      setLoading(true);
    }
    // setLoading(false);
    return Promise.resolve(json)
  
}

  const create_tenant = () => {
    // Check if all fields are filled
    console.log({
      tenantName,
      adminFirstName,
      adminLastName,
      adminEmail,
      adminPassword,
    });
    if (
      !tenantName ||
      !adminFirstName ||
      !adminLastName ||
      !adminEmail ||
      !adminPassword ||
      !confirmPassword
    ) {
      setToastMessage('Veuillez remplir tous les champs');
      setToastSeverity('error');
      setShowToast(true);
      return;
    }

    // Validate email format
    if (!validateEmail(adminEmail)) {
      setEmailError('Veuillez entrer une adresse e-mail valide');
      return;
    }

    // Check if passwords match
    if (adminPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    // Clear errors and proceed with form submission
    setError('');
    setEmailError('');
    setLoading(true);
    const data={
      "name": tenantName,
      "adminFirstName": adminFirstName,
      "adminLastName": adminLastName,
      "adminEmail": adminEmail,
      "adminPassword": adminPassword
    }
    createNewTenant(data);
    console.log({
      tenantName,
      adminFirstName,
      adminLastName,
      adminEmail,
      adminPassword,
    });

    // Show success message (as an example)
    setToastMessage('Tenant ajouté avec succès');
    setToastSeverity('success');
    setShowToast(true);
  };
  if (loading) {
    return (
      <Grid 
      container 
      justifyContent="center" 
      alignItems="center" 
      style={{ height: '100vh', position: 'relative' }}
    >
      {/* Circular Progress underneath the image */}
      <CircularProgress 
        size={170} // Adjust the size as needed
        style={{ position: 'absolute' }}
      />
    
      {/* Image on top */}
      <img
        alt="logo"
        src={Logo_Kalanke}
        style={{
          width: '120px', // Adjust the size to fit over the CircularProgress
          position: 'absolute',
        }}
      />
    </Grid>
    

    );
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f0f2f5"
    >
      <Card sx={{ maxWidth: 400, width: '100%', p: 2 }}>
        <CardContent>
          {/* Placeholder for Logo */}
          <Box display="flex" justifyContent="center" mb={2}>
            <img src={Logo_Kalanke} alt="Logo" style={{ height: 50 }} />
          </Box>

          <Typography variant="h5" align="center" gutterBottom>
            Ajouter un tenant
          </Typography>

          <TextField
            label="Nom du tenant"
            variant="outlined"
            fullWidth
            margin="normal"
            value={tenantName}
            onChange={(e) => setTenantName(e.target.value)}
          />

          <TextField
            label="Prénom d'admin"
            variant="outlined"
            fullWidth
            margin="normal"
            value={adminFirstName}
            onChange={(e) => setAdminFirstName(e.target.value)}
          />

          <TextField
            label="Nom d'admin"
            variant="outlined"
            fullWidth
            margin="normal"
            value={adminLastName}
            onChange={(e) => setAdminLastName(e.target.value)}
          />

          <TextField
            label="Email d'admin"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />

          <TextField
            label="Mot de passe d'admin"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? 'text' : 'password'}
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Répéter le mot de passe d'admin"
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={create_tenant}
          >
            Valider
          </Button>
        </CardContent>
      </Card>

      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowToast(false)} severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateTenantPage;
