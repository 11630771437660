import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  useMediaQuery,
  Typography,
  TextField,
  Select,
  MenuItem,
  Container,
  Switch,
  Tooltip,
  InputAdornment,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  FormControlLabel,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { paramsApi } from "../../../api/params-api";
import { useMounted } from "../../../hooks/use-mounted";
import { stepApi } from "../../../api/step-api";
import { auditsApi } from "../../../api/audit-api";
import { validate } from "json-schema";
import { set } from "lodash";

export const Parameters = () => {
  const { user } = useAuth();
  const isMounted = useMounted();
  const [automaticMail, setAutomaticMail] = useState(true);
  const [automaticMailToCustomer, setAutomaticMailToCustomer] = useState(true); 
  
  const [automaticMailUserFirstLogin, setAutomaticMailUserFirstLogin] = useState(true);
  const [automaticMailUserNewDevice, setAutomaticMailUserNewDevice] = useState(true);
  const [automaticMailAdminFirstLogin, setAutomaticMailAdminFirstLogin] = useState(true);
  const [automaticMailAdminNewDevice, setAutomaticMailAdminNewDevice] = useState(true);
  


  const getParams = useCallback(async () => {
    try {
      const automaticMail = await paramsApi.getParamAutomaticMail(
        user.admin_id
      );
      const automaticMailToCustomer =
        await paramsApi.getParamAutomaticMailToCustomer(user.admin_id);

        const automaticMailUserFirstLogin =
        await paramsApi.getParamAutomaticMailUserFirstLogin(user.admin_id);
        const automaticMailUserNewDevice =
        await paramsApi.getParamAutomaticMailUserNewDevice(user.admin_id);
        const automaticMailAdminFirstLogin =
        await paramsApi.getParamAutomaticMailAdminFirstLogin(user.admin_id);
        const automaticMailAdminNewDevice =
        await paramsApi.getParamAutomaticMailAdminNewDevice(user.admin_id);



      if (isMounted()) {
        setAutomaticMail(automaticMail.automatic_mails_send === 1);
        setAutomaticMailToCustomer(
          automaticMailToCustomer.automatic_mails_send_to_customer === 1
        );
        setAutomaticMailUserFirstLogin(automaticMailUserFirstLogin.automatic_mails_UserFirstLogin === 1);
        setAutomaticMailUserNewDevice(automaticMailUserNewDevice.automatic_mail_UserNewDevice === 1)
        setAutomaticMailAdminFirstLogin(automaticMailAdminFirstLogin.automatic_mails_AdminFirstLogin === 1)
        setAutomaticMailAdminNewDevice(automaticMailAdminNewDevice.automatic_mails_AdminNewDevice === 1)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getSteps = useCallback(async () => {
    try {
      const steps = await stepApi.getAllSteps(user.admin_id);
      if (isMounted()) {
        setSteps(steps);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getAudits = useCallback(async () => {
    try {
      const audits = await auditsApi.getAllAudits(user.admin_id);
      if (isMounted()) {
        setAudits(audits);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getParams();
    getSteps();
    getAudits();
  }, [getParams, getSteps, getAudits]);

  const setParamAutomaticMail = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMail(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleChangeAutomaticMail = (value) => {
    setAutomaticMail(!value);
    let paramAutomaticMailBool = true;
    if (!value) {
      paramAutomaticMailBool = 1;
    } else {
      paramAutomaticMailBool = 0;
    }
    setParamAutomaticMail(paramAutomaticMailBool);
  };
  
  const setParamAutomaticMailToCustomer = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMailToCustomer(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleChangeAutomaticMailToCustomer = (value) => {
    setAutomaticMailToCustomer(!value);
    let paramAutomaticMailBool = true;
    if (!value) {
      paramAutomaticMailBool = 1;
    } else {
      paramAutomaticMailBool = 0;
    }
    setParamAutomaticMailToCustomer(paramAutomaticMailBool);
  };



  const handleChangeAutomaticMailAdminNewDevice = (value) => {
    setAutomaticMailAdminNewDevice(!value);
    let paramAutomaticMailAdminNewDeviceBool = true;
    if (!value) {
      paramAutomaticMailAdminNewDeviceBool = 1;
    } else {
      paramAutomaticMailAdminNewDeviceBool = 0;
    }
    setParamAutomaticMailAdminNewDevice(paramAutomaticMailAdminNewDeviceBool);
  };  
  const handleChangeAutomaticMailUserNewDevice = (value) => {
    setAutomaticMailUserNewDevice(!value);
    let paramAutomaticMailUserNewDeviceBool = true;
    if (!value) {
      paramAutomaticMailUserNewDeviceBool = 1;
    } else {
      paramAutomaticMailUserNewDeviceBool = 0;
    }
    setParamAutomaticMailUserNewDevice(paramAutomaticMailUserNewDeviceBool);
  };  
  const handleChangeAutomaticMailAdminFirstLogin = (value) => {
    setAutomaticMailAdminFirstLogin(!value);
    let paramAutomaticMailAdminFirstLoginBool = true;
    if (!value) {
      paramAutomaticMailAdminFirstLoginBool = 1;
    } else {
      paramAutomaticMailAdminFirstLoginBool = 0;
    }
    setParamAutomaticMailAdminFirstLogin(paramAutomaticMailAdminFirstLoginBool);
  }; 
  const handleChangeAutomaticMailUserFirstLogin = (value) => {
    setAutomaticMailUserFirstLogin(!value);
    let paramAutomaticMailUserFirstLoginBool = true;
    if (!value) {
      paramAutomaticMailUserFirstLoginBool = 1;
    } else {
      paramAutomaticMailUserFirstLoginBool = 0;
    }
    setParamAutomaticMailUserFirstLogin(paramAutomaticMailUserFirstLoginBool);
  };

  const setParamAutomaticMailAdminNewDevice = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMailAdminNewDevice(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []); 
  const setParamAutomaticMailUserNewDevice = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMailUserNewDevice(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []); 
  const setParamAutomaticMailAdminFirstLogin = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMailAdminFirstLogin(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []);  
  const setParamAutomaticMailUserFirstLogin = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMailToCustomer(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []);
  return (
    <>
      {user.access_level === 0 ? (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 11,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 5 }}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
                    Paramètres
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <MenuItem>
                <Switch
                  checked={automaticMail}
                  onChange={() => {
                    handleChangeAutomaticMail(automaticMail);
                  }}
                />
                <Tooltip title="Contrôle les tâches cron">
                  <Typography>Envoi de mails automatique</Typography>
                </Tooltip>
              </MenuItem>
              <MenuItem>
                <Switch
                  checked={automaticMailToCustomer}
                  onChange={() => {
                    handleChangeAutomaticMailToCustomer(
                      automaticMailToCustomer
                    );
                  }}
                />
                <Tooltip title="Contrôle l'envoi de mail à l'apprenant">
                  <Typography>
                    Envoi de mails automatique à l'apprenant
                  </Typography>
                </Tooltip>
              </MenuItem>
{/* ////////////////////////////////////////////////////////////////////////////////////// */}

              <MenuItem>
                <Switch
                  checked={automaticMailAdminFirstLogin}
                  onChange={() => {
                    handleChangeAutomaticMailAdminFirstLogin(automaticMailAdminFirstLogin);
                  }}
                />
                <Tooltip title="Contrôle les tâches cron">
                  <Typography>Vérifivation des emails pour la première connexion des admins</Typography>
                </Tooltip>
              </MenuItem>

              <MenuItem>
                <Switch
                  checked={automaticMailAdminNewDevice}
                  onChange={() => {
                    handleChangeAutomaticMailAdminNewDevice(automaticMailAdminNewDevice);
                  }}
                />
                <Tooltip title="Contrôle les tâches cron">
                  <Typography>Authentification à deux facteurs pour les admins</Typography>
                </Tooltip>
              </MenuItem>
              
              <MenuItem>
                <Switch
                  checked={automaticMailUserFirstLogin}
                  onChange={() => {
                    handleChangeAutomaticMailUserFirstLogin(automaticMailUserFirstLogin);
                  }}
                />
                <Tooltip title="Contrôle les tâches cron">
                  <Typography>Vérifivation des emails pour la première connexion des apprenants</Typography>
                </Tooltip>
              </MenuItem>

              <MenuItem>
                <Switch
                  checked={automaticMailUserNewDevice}
                  onChange={() => {
                    handleChangeAutomaticMailUserNewDevice(automaticMailUserNewDevice);
                  }}
                />
                <Tooltip title="Contrôle les tâches cron">
                  <Typography>Authentification à deux facteurs pour les admins</Typography>
                </Tooltip>
              </MenuItem>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 11,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 5 }}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography>
                    Vous n'avez pas les droits pour voir cette page
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Parameters;
