import { Box, Grid } from "@mui/material";

import { ComingDates } from "../../components/dashboard/statistiques/coming-dates";
import { FeedbacksNumber } from "../../components/dashboard/statistiques/feedbacks-number";
import { LastMessages } from "../../components/dashboard/statistiques/last-messages";
import LastActivities from "../../components/dashboard/statistiques/last-activities";

const DashboardFormateur = (props) => {
  return (
    <Box>
      <Grid sm={10} md={10} lg={6}>
        <ComingDates />
      </Grid>
      <Grid
        container
        xs={12}
        sx={{ mt: 3, justifyContent: "space-between" }}
        spacing={1}
      >
        <Grid
          item
          xs={4}
          sx={{
            minHeight: 500,
            maxHeight: 500,
          }}
        >
          <FeedbacksNumber />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            minHeight: 500,
            maxHeight: 500,
          }}
        >
          <LastMessages />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            minHeight: 500,
            maxHeight: 500,
          }}
        >
          <LastActivities />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardFormateur;
