import React, { useState, useEffect, Suspense, lazy, useCallback, useRef } from "react";
import GridLayout from "react-grid-layout";
import { widgetsApi } from "../../api/widget-api";
import { Box, Container } from "@mui/material";
import WidgetSelector from "./widgets/widget-selector";

const DashboardAdmin = ({
  progression,
  meanTime,
  isLoadingTime,
  admin_id,
  isSelectingWidgets,
  isEditMode,
}) => {
  const [layout, setLayout] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [allWidgets, setAllWidgets] = useState([]);
  const componentCache = useRef({});

  useEffect(() => {
    let isMounted = true;
    const fetchWidgets = async () => {
      const widgets = await widgetsApi.getWidgets();
      if (isMounted) {
        setAllWidgets(widgets);
      }
    };
    fetchWidgets();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {


    const fetchPreferences = async () => {
      const preferences = await widgetsApi.getAdminPreferences(admin_id);
      const activeWidgets =
        preferences &&
        preferences.length > 0 &&
        preferences.filter((pref) => pref.is_selected);

      setSelectedWidgets(activeWidgets);

      const initialLayout =
        activeWidgets &&
        activeWidgets.length > 0 &&
        activeWidgets.map((widget) => ({
          i: widget.widget.component_name,
          x: widget.position_x,
          y: widget.position_y,
          w: widget.width,
          h: widget.height,
        }));
      setLayout(initialLayout);
    };
    fetchPreferences();
  }, [admin_id]);

  const toggleWidget = (widgetName) => {
    if (!isSelectingWidgets) {
      return;
    }
    const widgetExists = selectedWidgets.some(
      (widget) => widget.widget.component_name === widgetName
    );

    let updatedWidgets;
    if (widgetExists) {
      updatedWidgets = selectedWidgets.map((widget) =>
        widget.widget.component_name === widgetName
          ? { ...widget, is_selected: !widget.is_selected }
          : widget
      );
      updatedWidgets = updatedWidgets.filter((widget) => widget.is_selected);
    } else {
      const maxY = Math.max(...layout.map((l) => l.y + l.h), 0);
      const fullWidthWidget = layout.find((l) => l.x === 0 && l.w === 12);
      const newY = fullWidthWidget ? fullWidthWidget.y + fullWidthWidget.h : maxY;
      const widgetToAdd = allWidgets.find(
        (widget) => widget.component_name === widgetName
      );
      if (widgetToAdd) {
        const newWidget = {
          widget: widgetToAdd,
          is_selected: true,
          position_x: 0,
          position_y: newY,
          width: 4,
          height: 2,
        };
        updatedWidgets = [...selectedWidgets, newWidget];
      }
    }
    setSelectedWidgets(updatedWidgets);

    const updatedLayout = updatedWidgets.map((widget) => ({
      i: widget.widget.component_name,
      x: widget.position_x,
      y: widget.position_y,
      w: widget.width,
      h: widget.height,
    }));

    setLayout(updatedLayout);
    savePreferences(updatedWidgets);
  };

  const savePreferences = async (widgets) => {
    const preferences =
      allWidgets &&
      allWidgets.length > 0 &&
      allWidgets.map((widget) => {
        const selectedWidget = widgets.find(
          (w) => w.widget.component_name === widget.component_name
        );
        return {
          widget_name: widget.component_name,
          is_selected: selectedWidget ? selectedWidget.is_selected : false,
          position_x: selectedWidget ? selectedWidget.position_x : 0,
          position_y: selectedWidget ? selectedWidget.position_y : 0,
          width: selectedWidget ? selectedWidget.width : 4,
          height: selectedWidget ? selectedWidget.height : 2,
        };
      });
    await widgetsApi.setPreferences(admin_id, preferences);
  };

  const onLayoutChange = (newLayout) => {
    if (JSON.stringify(newLayout) !== JSON.stringify(layout)) {
      setLayout(newLayout);
      const updatedWidgets = selectedWidgets.map((widget) => {
        const layoutItem = newLayout.find(
          (item) => item.i === widget.widget.component_name
        );
        return layoutItem
          ? {
              ...widget,
              position_x: layoutItem.x,
              position_y: layoutItem.y,
              width: layoutItem.w+2,
              height: layoutItem.h,
              // px_width: widget.widget.px_dynamic_width,
              // px_height: widget.widget.px_dynamic_height,
            }
          : widget;
      });
      setSelectedWidgets(updatedWidgets);
      savePreferences(updatedWidgets);
    }
  };

  const loadComponent = useCallback(async (componentName) => {
    if (!componentCache.current[componentName]) {
      try {
        const component = await import(`./widgets/${componentName}`);
        componentCache.current[componentName] = component;
      } catch (err) {
        const component = await import(
          `../../components/dashboard/statistiques/${componentName}`
        );
        componentCache.current[componentName] = component;
      }
    }
    return componentCache.current[componentName];
  }, []);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: 0,
        // width:'fit-content',
        // display: 'flex',
        width: '100%',
        backgroundColor: isSelectingWidgets ? "#FFFFFF" : "#F1F1F9",
      }}
    >
      {isSelectingWidgets && isEditMode && (
        <WidgetSelector
          admin_id={admin_id}
          selectedWidgets={selectedWidgets}
          toggleWidget={toggleWidget}
        />
      )}
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={150}
        width={window.innerWidth * 0.8}
        onLayoutChange={isEditMode ? onLayoutChange : () => {}}
        draggableHandle={isEditMode ? ".drag-handle" : null}
        isDraggable={isEditMode}
        isResizable={isEditMode}
        compactType="vertical"
        margin={[10, 10]}
      >
        {selectedWidgets.map((widget) => {
          const WidgetComponent = React.lazy(() =>
            loadComponent(widget.widget.component_name)
          );

          return (
            <Box
              key={widget.widget.component_name}
              data-grid={{
                i: widget.widget.component_name,
                x: widget.position_x,
                y: widget.position_y,
                w: ((12/((window.innerWidth-80)*0.8))*widget.widget.px_dynamic_width)+0.7,
                h: (widget.widget.px_dynamic_height/150)+0.2,
              }}
              className="drag-handle"
              sx={{
                border: isEditMode ? "2px solid #FFFFFF" : "none",
                backgroundColor: isEditMode ? "#F0F0F2" : "none",
                width: `${widget.widget.px_dynamic_width}px`,
                height: `${(widget.widget.px_dynamic_height/150)*0.9}`,
                boxShadow: isEditMode ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "none",
                borderRadius: isEditMode ? 2 : "none",
              }}
            >
              <Suspense fallback={<div>Loading widget...</div>}>
                <WidgetComponent
                  progression={progression}
                  isLoadingTime={isLoadingTime}
                  time={meanTime}
                />
              </Suspense>
            </Box>
          );
        })}
      </GridLayout>
    </Container>
  );
};

export default DashboardAdmin;
