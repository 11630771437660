import { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";

import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import Slide from "@mui/material/Slide";

export const FeedbacksNumber = () => {
  const { user } = useAuth();
  const [wsConnection, setWsConnection] = useState(null);
  const [firstTurnData, setFirstTurnData] = useState(null);
  const [secondTurnData, setSecondTurnData] = useState(null);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Connexion au WebSocket lors du montage du composant
    const url =
      process.env.REACT_APP_WS_URL + `subdomain/${globalThis.localStorage.getItem('tenant')}/socket-server/?user_id=${user.admin_id}`;
    // const url = `ws://127.0.0.1:7000/ws/socket-server/?user_id=${user.admin_id}`;
    const cnxSocket = new WebSocket(url);
    cnxSocket.onopen = () => {
      setWsConnection(cnxSocket);
      cnxSocket.send(
        JSON.stringify({
          message: "Notifications Update",
          user_id: user.admin_id,
        })
      );
    };
    cnxSocket.onmessage = (e) => {
      cnxSocket.send(
        JSON.stringify({
          message: "Notifications Update",
          user_id: user.admin_id,
        })
      );
      let data = JSON.parse(e.data);
      setFirstTurnData(data.data.first_turn);
      setSecondTurnData(data.data.second_turn);
    };

    setLoading(false);

    // fermer la connexion WebSocket lors du démontage du composant
    return () => {
      if (wsConnection) {
        wsConnection.close();
      }
    };
  }, [open]);

  useEffect(() => {
    if (secondTurnData && firstTurnData) {
      setTotalDataLength(secondTurnData.length + firstTurnData.length);
    }
  }, [firstTurnData, secondTurnData]);

  // useEffect(() => {
  //   console.log(wsConnection);
  //   console.log(loading);
  // }, [wsConnection, loading]);



  return (
    <Card
      sx={{
        backgroundColor: process.env.REACT_APP_NEW_PRIMARY_COLOR,
        borderRadius: 3,
        minHeight: 500,
        maxHeight: 500,
        overflowY: "auto",
        scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`,
      }}
    >
      <CardHeader
        title={
          <Grid>
            {totalDataLength && totalDataLength > 0 ? (
              <Typography
                variant="h6"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "black" : "white",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                  {totalDataLength}
                </span>{" "}
                Feedbacks à remplir
              </Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "black" : "white",
                }}
              >
                Feedbacks
              </Typography>
            )}
          </Grid>
        }
      />
      <Divider />
      <Box>
        {wsConnection !== null ? (
          loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 3,
                height: 300,
              }}
            >
              <CircularProgress sx={{ color: "white" }} />
            </Box>
          ) : (
            <>
              {
                (!firstTurnData && !secondTurnData) ||
              (firstTurnData &&
              secondTurnData &&
              firstTurnData.length === 0 &&
              secondTurnData.length === 0) ? (
                <Box
                  sx={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Slide
                    direction="right"
                    in={
                      true
                    }
                  >
                    <CheckIcon
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "dark" ? "black" : "white",
                        fontSize: 150,
                      }}
                    />
                  </Slide>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "black" : "white",
                    }}
                  >
                    Il n&apos;y a pas de feedbacks à remplir
                  </Typography>
                </Box>
              ) : (
                <>
                  {firstTurnData &&
                    firstTurnData.map((notification) => (
                      <Box key={notification.id}>
                        {notification.has_feedback_turn_1 === false && (
                          <ListItem
                            divider
                            key={notification.id}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ListItemAvatar>
                              <IconButton
                                sx={{
                                  color: "white",
                                }}
                              >
                                <FeedbackIcon fontSize="small" />
                              </IconButton>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="bold"
                                >
                                  Feedback à faire pour les axes
                                  d'améliorations:
                                </Typography>
                              }
                              secondary={
                                <Box>
                                  <Box style={{ color: "white" }}>
                                    {"L'apprenant "}
                                    {notification.firstname}{" "}
                                    {notification.lastname}
                                    {" pour l'audit "}
                                    <Link
                                      component="a"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.location.href = `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers/${notification.users_id}`;
                                      }}
                                      color="inherit"
                                      underline="none"
                                      style={{ color: "white" }}
                                    >
                                      {notification.audit_name || ""}
                                    </Link>
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        )}
                      </Box>
                    ))}

                  {secondTurnData &&
                    secondTurnData.map((notification) => (
                      <Box key={notification.id}>
                        {notification.has_feedback_turn_2 === false && (
                          <ListItem divider key={notification.id}>
                            <ListItemAvatar>
                              <IconButton
                                sx={{
                                  color: "white",
                                }}
                              >
                                <FeedbackIcon fontSize="small" />
                              </IconButton>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="bold"
                                >
                                  Feedback à faire pour le tour 2:
                                </Typography>
                              }
                              secondary={
                                <Box
                                  onClick={(event) => {
                                    event.preventDefault();
                                    window.location.href = `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers/${notification.users_id}`;
                                  }}
                                >
                                  <Box style={{ color: "white" }}>
                                    {"L'apprenant "}
                                    {notification.firstname}{" "}
                                    {notification.lastname}
                                    {" pour l'audit "}
                                    <Link
                                      component="a"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.location.href = `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers/${notification.users_id}`;
                                      }}
                                      color="inherit"
                                      underline="none"
                                      style={{ color: "white" }}
                                    >
                                      {notification.audit_name || ""}
                                    </Link>
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        )}
                      </Box>
                    ))}
                </>
              )}
            </>
          )
        ) : (
          <Box
            sx={{
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Slide
              direction="right"
              in={wsConnection === null}
            >
              <CheckIcon
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "black" : "white",
                  fontSize: 150,
                }}
              />
            </Slide>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) =>
                  theme.palette.mode === "dark" ? "black" : "white",
              }}
            >
              Il n&apos;y a pas de feedbacks à remplir
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};
