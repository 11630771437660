import { useEffect, useRef, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Toolbar,
  ListItemIcon,
  Typography,
  InputBase,Grid,CircularProgress,Badge
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AccountPopover } from "./account-popover";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuth } from "../../hooks/use-auth";
import { NotificationsPopover } from "./notifications-popover";
import { Chat } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from '@mui/material/styles';
import Logo_Kalanke from './../../images/Logo-Kalanke.png';
import { conversationApi } from '../../../src/api/conversation-api';

const DashboardNavbarRoot = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "openSide",
})(({ theme, openSide }) => ({
  backgroundColor: "grey",
  maxWidth: "100%",
  left: openSide || [theme.breakpoints.up("lg")] ? 280 : 40,
  minHeight: 120,
  zIndex: theme.zIndex.drawer + 1,
  ...(theme.palette.mode === "light"
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const AccountButton = () => {
  const { user } = useAuth();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          ml: 0,
          mr: 2,
        }}
      >
        <Avatar
          sx={{
            height: 50,
            width: 50,
            mx: 0,
          }}
          src={user.avatar}
        />
        <Box sx={{ ml: 1 }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "black", ml: 2 }}
          >
            {user.firstname} {user.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: "black" }}>
            {user.id_service === 0 ? "Administrateur" : "Expert"}
          </Typography>
        </Box>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, open, openSide, setOpenSide, ...other } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme(); 
  const [loading, setLoading] = useState(true); // Loading state to manage tenant check
  const { tenant } = useParams();
  const [messagesNotRead,setMessagesNotRead] = useState(0)
  const [socket, setSocket] = useState(null);

  const checkTenant = async () => {
    if (tenant==globalThis.localStorage.getItem('tenant')){
      setLoading(false);
    }
    else {
      navigate(`/not-found`);
      setLoading(false);
    }
   
  };

  useEffect(() => {
    checkTenant();
    getNotReadMessages();
  }, []);
  useEffect(()=> {
    
    getNotReadMessages();
  }, [globalThis.localStorage.getItem('messages-non-lus')]);

  useEffect(() => {
    const url =  process.env.REACT_APP_WS_URL + `subdomain/${globalThis.localStorage.getItem('tenant')}/chat-server/`;
    // const ws = new WebSocket(`ws://127.0.0.1:7000/ws/chat-server/`);
    const ws = new WebSocket(url);
    setSocket(ws)

    ws.onopen = () => {
      console.log('Connected to WebSocket');
    };

   
    ws.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
      
        const newMessageObject = {
          id: eventData.id,
          type_author: eventData.type_author,
          conversation_id: eventData.conversation_id,
          content: eventData.content,
          sent_at: eventData.sent_at,
        };
      
        if (eventData.type === "chat.message") {
          getNotReadMessages()
        }
      };
      
    ws.onclose = () => {
      console.log('Disconnected from WebSocket');
    };

    return () => {
      ws.close();
    };
  }, []);
 const getNotReadMessages=async () => {
    try {
      const messageData = await conversationApi.getNotReadMessages(user.admin_id)
      
           setMessagesNotRead(messageData.notReadMessages)
           localStorage.setItem('messages-non-lus', messageData.notReadMessages);
      
  } catch(err) {
      console.error(err);
      setMessagesNotRead(0);
  }
  };
  useEffect(() => {
    const handleMessagesUpdate = () => {
      getNotReadMessages();
    };
  
    // Listen for the custom event
    window.addEventListener('messagesNonLusUpdated', handleMessagesUpdate);
  
    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('messagesNonLusUpdated', handleMessagesUpdate);
    };
  }, []);

  useEffect(() => {
    console.log(openSide);
  }, [openSide]);

  if (loading) {
    return (
      <Grid 
      container 
      justifyContent="center" 
      alignItems="center" 
      style={{ height: '100vh', position: 'relative' }}
    >
      {/* Circular Progress underneath the image */}
      <CircularProgress 
        size={170} // Adjust the size as needed
        style={{ position: 'absolute' }}
      />
    
      {/* Image on top */}
      <img
        alt="logo"
        src={Logo_Kalanke}
        style={{
          width: '120px', // Adjust the size to fit over the CircularProgress
          position: 'absolute',
        }}
      />
    </Grid>
    

    );
  }

  return (
    <DashboardNavbarRoot openSide={openSide} {...other}>
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: "white",
          maxWidth: openSide || [theme.breakpoints.up("lg")] ? "86%" : "98%",
          height: "120px",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        {/* Champ de recherche */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F1F1F9",
            borderRadius: 1,
            padding: "15px 15px",
            marginLeft: 4,
            width: "300px",
          }}
        >
          <SearchIcon sx={{ color: "gray", mr: 1 }} />
          <InputBase
            placeholder="Rechercher..."
            sx={{ flex: 1, color: "black" }}
          />
        </Box>

        {/* Notifications et messages */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NotificationsPopover />
          {messagesNotRead===0? <Box component={ButtonBase} sx={{ mr: 2 }}>
            <Chat
              sx={{ fontSize: 40, color: "#8282CA" }}
              onClick={() => {
                navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/discussion`);
              }}
            />
          </Box> :
          <Box component={ButtonBase} sx={{ mr: 2 }}>
  <Badge
    badgeContent={messagesNotRead} // The number you want to display inside the circle
    color="error" // Red color for the badge
    sx={{
      '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid #fff`, // Optional: to add a border around the badge
        padding: '0 4px',
      },
    }}
  >
    <Chat
      sx={{ fontSize: 40, color: "#8282CA" }}
      onClick={() => {
        navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/discussion`);
      }}
    />
  </Badge>
</Box>}

          {/* Affichage du profil */}
          <AccountButton />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
