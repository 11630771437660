import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import pauline from './../images/new_pauline_1.png';
import {Card,
  Box,InputLabel,
  Button,
  TextField,
  Typography,
  Container,
  Alert,Grid, Icon, CircularProgress, IconButton, InputAdornment
} from '@mui/material';
import Logo_Kalanke from './../images/Logo-Kalanke.png';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ChangePasswordFirstLogin = () => {
  const { tenant, token, } = useParams(); // Extract URL params
  const navigate = useNavigate(); // For navigation if needed

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [target_2, setTarget_2] = useState('first_login');
  const [error, setError] = useState(''); // To display validation errors
  const [loading, setLoading] = useState(true); // Loading state to manage tenant check
  const [first_login,setFirst_login]=useState("");
  const [new_device,setNew_device]=useState("");
  const [token_data,setToken_data]=useState({});
  const [valid,setValid]=useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);

  const [conditions, setConditions] = useState({
    length: {
      name:'Au moins 9 charactères',
      value:false
    },
    uppercase:  {
      name:'Au moins 1 lettre majuscule',
      value:false
    },
    lowercase:  {
      name:'Au moins 1 lettre miniscule',
      value:false
    },
    specialChar:  {
      name:'Au moins 1 symbole',
      value:false
    },
    number:  {
      name:'Au moins 1 chiffre',
      value:false
    },
  });

  const checkConditions = (value) => {
    const updatedConditions = {
      length: { ...conditions.length, value: value.length >= 9 },
      uppercase: { ...conditions.uppercase, value: /[A-Z]/.test(value) },
      lowercase: { ...conditions.lowercase, value: /[a-z]/.test(value) },
      specialChar: { ...conditions.specialChar, value: /[^a-zA-Z0-9]/.test(value) },
      number: { ...conditions.number, value: /[0-9]/.test(value) },
    };
    setConditions(updatedConditions);
  
    // Check if all conditions are met and set `valid` to true if so
    const allConditionsMet = Object.values(updatedConditions).every((condition) => condition.value === true);
     setValid(allConditionsMet);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    checkConditions(value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };


const checkAdminFirstLoginAndIp=async(data)=> {
  
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/check_login_and_ip/`
    const values = {
      "admin_id": data.admin_id,
     "key":data.random_key
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`,
}}
);
    const json = await resp.json();
    setFirst_login(json.first_login);
    setNew_device(json.new_device);
    if (json.new_device==true && json.first_login==false ){
      // localStorage.removeItem('accessToken');
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard`); 
    }
    // setLoading(false);
    return Promise.resolve(json)
  
}
const confirmAdminEmail=async()=> {
    
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/confirm-admin-email/`
    // const url = `http://localhost:3000/subdomain/${globalThis.localStorage.getItem('tenant')}/api/confirm-admin-email/`
    const values = {
      // "old_password":oldPassword,
      "new_password":newPassword,
      // "email":email,
      "admin_id":token_data.admin_id,
      "key":token_data.random_key

     
    };
    const accessToken = token_data.token;
    const resp = await fetch(url, {
      
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
}}
);
    const json = await resp.json();

    // Set access token if successful
    localStorage.setItem('accessToken', json.jwt);
    
    if (globalThis.localStorage.getItem("accessToken")) {
      const tenant = globalThis.localStorage.getItem('tenant');
      navigate(`/${tenant}/dashboard`);
    }


    return json;
  
}
const decodeVerificationData = async(encodedData) => {
  try {
    // Decode the Base64 string
    const jsonData = atob(encodedData);
    const data = JSON.parse(jsonData);
    console.log("data");
    console.log(data);
    setToken_data(data);
    if(data.target === 'ip_confirm'&& globalThis.localStorage.getItem('accessToken'))
    {
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard`); 
    }
    // localStorage.setItem('accessToken', data.token);
    await verify_link_active(data);
    if (data.target === 'ip_confirm'){
    console.log("ip_confirm");
      localStorage.setItem('accessToken', data.token);
      AdminNewIp(data);
    }
  } catch (error) {
    console.error("Failed to decode verification data:", error);
    // return null;
  }finally{
    setLoading(false);
  }
};
const verify_link_active=async(data)=>{
   const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/check_link/`
    const values = {
      
      "admin_id":data.admin_id,
      "key":data.random_key
     
    };
    const accessToken = data.token;
    const resp = await fetch(url, {
      
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
}}
);
    const json = await resp.json();
    if(json.ok==false){
     navigate(`/`); 
    }
    else{
      await checkAdminFirstLoginAndIp(data);
    }

    return Promise.resolve(json)
}

const AdminNewIp=async(data)=> {  
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/admin_new_ip/`
    const values = {     
      "admin_id":data.admin_id,
     "key":data.random_key,
    };
    const accessToken = data.token;
    const resp = await fetch(url, {      
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
}}
);
    const json = await resp.json();
  return Promise.resolve(json)
  
}

// const isPasswordValid = (password) => {
//   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/;
//   return passwordRegex.test(password);
// };

useEffect(async() =>{
      await decodeVerificationData(token);       
    },[]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // if (newPassword !== confirmNewPassword) {
    //   setError("Les champs nouveau mot de passe et sa confirmation ne sont pas identiques");
    //   return;
    // }
    // if (!isPasswordValid(newPassword)) {
    //   setError("Le mot de passe doit contenir au moins 9 caractères, avec une majuscule, une minuscule, un chiffre, et un symbole");
    //   return;
    // }
    const resp= await confirmAdminEmail();
    console.log(resp);
    if (resp.msg =="success"){
    setError('');
    navigate(`/${tenant}/dashboard`);
    }
    else{
        setError('Une erreur est surveune');
        return;
    }
  };
  if (loading) {
    return (
      <Grid 
      container 
      justifyContent="center" 
      alignItems="center" 
      style={{ height: '100vh', position: 'relative' }}
    >
      {/* Circular Progress underneath the image */}
      <CircularProgress 
        size={170} // Adjust the size as needed
        style={{ position: 'absolute' }}
      />
    
      {/* Image on top */}
      <img
        alt="logo"
        src={Logo_Kalanke}
        style={{
          width: '120px', // Adjust the size to fit over the CircularProgress
          position: 'absolute',
        }}
      />
    </Grid>
    

    );
  }
    return (

      <Grid container style={{ height: '100vh', width: '100vw' }}>
      {/* Left Side (Logo and Background) */}
      <Grid item style={{ height: '100%', width: '47%', backgroundColor: '#5d5db7', position: 'relative' }}>
        <img
          alt="logo"
          src={Logo_Kalanke}
          style={{ width: '200px', position: 'absolute', bottom: '60px', left: '60px', zIndex: 2 }}
        />
        <div style={{ width: '100%', height: '100%', display: 'flex', zIndex: 0 }}>
          <img alt="Image" src={pauline} style={{ width: '100%', opacity: 1 }} />
        </div>
      </Grid>

      {/* Right Side (Form) */}
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ height: '100%', width: '53%', backgroundColor: '#ffffff' }}
      >
    {token_data.target === 'first_login'&& <Container maxWidth="sm" sx={{ mt: 2 }}>
       <Box sx={{width:"90%"}}>
       <Box>
          <Typography style={{ textAlign: 'center', color: '#5d5db7', fontSize: '24px', fontFamily: '"Montserrat", sans-serif' }}>
            Kalanke
          </Typography>
          <Typography
            variant='h3'
            style={{ textAlign: 'center', color: '#5d5db7', fontSize: '26px', fontWeight: 900, fontFamily: '"Montserrat", sans-serif' }}
          >
            Back-Office
          </Typography>
        </Box>
          <Typography variant="h3" sx={{ mt: 5,textAlign:'center' }}>Bienvenue sur Kalanke CRM!</Typography>
          <Typography variant="h5" sx={{ fontWeight: 400,textAlign:'center' }}>Pour activer votre compte veuillez changer votre mot de passe</Typography>
            </Box>
       
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          
          <TextField
                label="Nouveau mot de passe"
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handlePasswordChange}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleNewPasswordVisibility} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}   />
        {Object.entries(conditions).map(([key, condition]) => (
                  <Box key={key} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon component={condition.value ? CheckIcon : CloseIcon} sx={{ color: condition.value ? 'green' : 'gray', mr: 1 }} />
                    <Typography
      variant="h5"
      style={{
        fontSize: '16px',
        color: newPassword !== "" ? (condition.value ? "green" : "grey") : 'gray'
      }}
    >
      {condition.name}
    </Typography>
                  </Box>
                ))}
              <TextField
                label="Confirmer nouveau mot de passe"
                type={showNewPassword ? 'text' : 'password'}
                value={confirmNewPassword}
                onChange={handleConfirmPasswordChange}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleNewPasswordVisibility} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: confirmNewPassword === newPassword ? "green" : "red",
                      },
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: confirmNewPassword === newPassword ? "green" : "red",
                      },
                    },
                  },
                }}
              />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={newPassword==""|| confirmNewPassword==''|| !valid|| newPassword!=confirmNewPassword}
            sx={{ mt: 2 }}
          >
            Valider
          </Button>
        </Box>
      </Container>}
      </Grid>
    </Grid>
    );
};

export default ChangePasswordFirstLogin;
