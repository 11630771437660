class StatsApi {
    async getGraphics(admin_id, audit_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/courses_stats/get/`
      const values = {
        "admin_id": admin_id,
        "audit_id": audit_id
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json)
    }
    async getAuditsDetails(admin_id, audit_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audits_details/get/`
      const values = {
        "admin_id": admin_id,
        "audit_id": audit_id
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
      return Promise.resolve(json)
    }
    async getCoursesSessionStats(admin_id, audit_id, start_session, end_session, session_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/courses_session_stats/get/`
      const values ={
        "admin_id": admin_id,
        "audit_id": audit_id,
        "start_session": start_session,
        "end_session": end_session,
        "session_id": session_id
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
      return Promise.resolve(json)
    }

    async getTcsStatsForAllUsersBySession(admin_id, tcs_id, start_session, end_session) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/tcs/statsForAllUsersBySession/`
      const values ={
        "admin_id": admin_id,
        "tcs_id": tcs_id,
        "start_session": start_session,
        "end_session": end_session
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
      return Promise.resolve(json)
    }

    async getAuditStatsForAllUsersBySession(admin_id, audit_id, start_session, end_session) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/audit/statsForAllUsersBySession/`
      const values ={
        "admin_id": admin_id,
        "audit_id": audit_id,
        "start_session": start_session,
        "end_session": end_session
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      const json = await resp.json();
      return Promise.resolve(json)
    }
  }

  
  export const statsApi = new StatsApi();