import React, { useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect } from "react";
import { auditsApi } from "../../../api/audit-api";
import AuditIntroForm from "./audit-intro-form";

function AuditChapterDetails(props) {
  const { number, auditId, setIsNewChapter, setShowDetails } = props;
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [chapterDetails, setChapterDetails] = useState(null);
  const [isChapterUpdated, setIsChapterUpdated] = useState(false);

  const getChapterDetails = async () => {
    try {
      const data = await auditsApi.getIntroductionPartByChapterNumber(
        user.admin_id,
        auditId,
        number
      );
      setChapterDetails(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getChapterDetails();
  }, []);

  useEffect(() => {
    if (isChapterUpdated) {
      setIsLoading(true);
      getChapterDetails();
      setIsChapterUpdated(false);
    }
  }, [isChapterUpdated]);

  return (
    <>
      {chapterDetails && (
        <AuditIntroForm
          auditId={auditId}
          editionMode={true}
          existingChapter={chapterDetails}
          setIsChapterUpdated={setIsChapterUpdated}
          setIsNewChapter={setIsNewChapter}
          setShowDetails={setShowDetails}
        />
      )}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid item xs={11} sx={{ mt: 1 }}>
          {chapterDetails && (
            <div
              dangerouslySetInnerHTML={{ __html: chapterDetails?.content }}
            />
          )}
        </Grid>
      )}
    </>
  );
}

export default AuditChapterDetails;
